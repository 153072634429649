import { BestSellingProductsSkeleton } from './components/best-selling-products-skeleton';
import { PromotionsBannersSkeleton } from './components/promotions-banners-skeleton';
import { BestSellingProducts } from './components/best-selling-products';
import { CategoryBestSeller } from './components/category-best-sellers';
import { PromotionsBanners } from './components/promotions-banners';
import { BrowseByCategory } from './components/browse-by-category';
import { CallToAction } from './components/call-to-action';
import { TopVendors } from './components/top-vendors';
import { AppContainer } from 'design-system';
import { useHomePage } from './useHomePage';
import styles from './styles.module.scss';
import { Faqs } from './components/faqs';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';
import classNames from 'classnames';

export const Home = () => {
  const { promotions, bestSellingProducts, homePageCategories, topVendors, faqs, isLoading } = useHomePage();

  return (
    <>
      <Helmet>
        <title>otrovato, B2B marketplace in Africa</title>
        <meta
          name="description"
          content="otrovato is designed to help buyers and sellers connect while simplifying the process of trading to make it accessible, efficient and secure all over Africa."
        />
        <meta
          name="keywords"
          content="B2B marketplace, Trade in Africa, Trade in Kenya, African Manufacturers and Suppliers, African Exporters and African Importers, African Trading Platform, Import products from Africa, Top B2B portals, best  African Suppliers, best B2B suppliers, b2b trade portal, top 10 b2b seller, food b2b marketplace, best b2b portals, furniture b2b marketplace, construction b2b marketplace, b2b food suppliers, b2b exporters, chemicals b2b marketplace"
        />
      </Helmet>

      <Box className={classNames(styles.promotion__section__container, styles.section__odd)}>
        <AppContainer>
          {!isLoading && promotions && <PromotionsBanners promotions={promotions} />}
          {isLoading && <PromotionsBannersSkeleton />}
          {!isLoading && bestSellingProducts && <BestSellingProducts products={bestSellingProducts} />}
          {isLoading && <BestSellingProductsSkeleton />}
        </AppContainer>
      </Box>

      {!isLoading && (
        <>
          <Box
            className={classNames(
              styles.section__container,
              styles.section__even,
              styles.categories__section__container,
            )}
          >
            <AppContainer>{homePageCategories && <CategoryBestSeller categories={homePageCategories} />}</AppContainer>
          </Box>

          <Box className={classNames(styles.section__container, styles.section__odd)}>
            <AppContainer>{topVendors && <TopVendors topVendors={topVendors} />}</AppContainer>
          </Box>

          <Box className={classNames(styles.section__even)}>
            <CallToAction />
          </Box>

          <Box
            className={classNames(
              styles.section__container,
              styles.section__odd,
              styles.categories__section__container,
            )}
          >
            <BrowseByCategory />
          </Box>

          {faqs && faqs.total && faqs.total > 0 ? (
            <Box className={classNames(styles.section__container, styles.section__faq)}>
              <AppContainer>
                <Faqs faqs={faqs} />
              </AppContainer>
            </Box>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
