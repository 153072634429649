import { CheckboxFilter, ResultsHeader, NopHits, CustomSlider, NopSort, PageSize } from 'components';
import { ReactComponent as FilterIcon } from 'design-system/public/icons/filter-narrow.svg';
import { Button, Loader, Pagination, Typography } from 'design-system';
import { CategorySlider } from './components/category-slider';
import { Box, Divider, Grid } from '@mui/material';
import { useCategory } from './useCategory';
import styles from './styles.module.scss';
import { Helmet } from 'react-helmet';

export const Category = () => {
  const {
    categoryId,
    categoryProductsResponse,
    categoryDetails,
    pageSizeOptions,
    totalItems,
    totalPages,
    pageNumber,
    pageSize,
    setPageSize,
    orderBy,
    availableSortOptions,
    setOrderBy,
    onChangePage,
    priceRange,
    onChangePriceRange,
    handleFilterRequest,
    manufacturerOptions,
    onChangeManufacturer,
    categoryLoading,
    categoryProductsLoading,
    isDownMd,
    manufacturerIds,
  } = useCategory();

  if (categoryLoading || categoryProductsLoading || !categoryProductsResponse) return <Loader />;

  return (
    <>
      <Helmet>
        <title>{categoryDetails?.categoryModelDto.metaTitle || categoryDetails?.categoryModelDto.name}</title>
        <meta
          name="description"
          content={
            categoryDetails?.categoryModelDto.metaDescription ? categoryDetails?.categoryModelDto.metaDescription : ''
          }
        />
        <meta
          name="keywords"
          content={categoryDetails?.categoryModelDto.metaKeywords ? categoryDetails?.categoryModelDto.metaKeywords : ''}
        />
      </Helmet>

      <Grid container spacing={{ xs: 0, md: 3 }}>
        {!isDownMd && (
          <Grid item xs={12} md={2.5}>
            <Box className={styles.filter__box}>
              <Typography variant="h5" component="h5" className={styles.section__subtitle}>
                Filter Items
              </Typography>

              <Divider className="mb-6" />

              <Typography variant="subtitle1" className="text-darkGray capitalize mb-3">
                Price Range
              </Typography>

              <CustomSlider
                min={0}
                max={categoryProductsResponse?.catalogProductsModel?.priceRangeFilter?.availablePriceRange.to || 10000}
                value={priceRange}
                onChange={onChangePriceRange}
              />

              <Divider className="my-8" />

              <CheckboxFilter
                manufacturerOptions={manufacturerOptions}
                onChangeManufacturer={onChangeManufacturer}
                manufacturerIds={manufacturerIds}
              />

              <Button
                size="small"
                className="mt-3 hover:bg-brand2"
                startIcon={<FilterIcon stroke="var(--white)" />}
                onClick={handleFilterRequest}
                label="Filter"
              />
            </Box>
          </Grid>
        )}

        <Grid item xs={12} md={9.5}>
          {categoryDetails && <CategorySlider categoryDetails={categoryDetails} />}

          <ResultsHeader
            itemsCount={totalItems}
            pageSizeComponent={
              <PageSize
                key={`category-${categoryId}-page-size`}
                pageSize={pageSize.toString()}
                pageSizeOptions={pageSizeOptions}
                setPageSize={setPageSize}
              />
            }
            sortComponent={
              <NopSort
                key={`category-${categoryId}-sort`}
                currentValue={orderBy}
                availableSortOptions={availableSortOptions}
                setOrderBy={setOrderBy}
                defaultValue={orderBy}
              />
            }
            manufacturerOptions={manufacturerOptions}
            onChangeManufacturer={onChangeManufacturer}
            onFilter={handleFilterRequest}
            categoryProductsResponse={categoryProductsResponse}
            priceRange={priceRange}
            setPriceRange={onChangePriceRange}
          />

          <NopHits hits={categoryProductsResponse?.catalogProductsModel?.products} />

          {categoryProductsResponse.catalogProductsModel.totalItems > 0 && (
            <Box className={isDownMd ? 'mt-6 mb-12' : 'my-[60px]'}>
              <Pagination
                key={`category-${categoryId}-pagination`}
                count={totalPages}
                page={pageNumber}
                handleChangePage={onChangePage}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};
