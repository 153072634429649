import { ReactComponent as DownloadIcon } from 'design-system/public/icons/Download.svg';
import { ReactComponent as HistoryIcon } from 'design-system/public/icons/history.svg';
import { ReactComponent as CloseIcon } from 'design-system/public/icons/close.svg';
import { QuotationObj } from 'services/types/quotation/quotationObj.type';
import PdfIcon from 'design-system/public/images/pdf.png';
import { Button, Modal, Typography } from 'design-system';
import { QuotationStatus } from '../../quotation-status';
import styles from './styles.module.scss';
import { Box, Link } from '@mui/material';
import moment from 'moment';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  handlePrimaryClick?: () => void;
  history: QuotationObj[];
};
const HistoryHeader = ({ history, setOpen }) => {
  return (
    <Box className="flex flex-col">
      <Box className="flex items-center justify-between">
        <Box className="flex items-center">
          <HistoryIcon />
          <Typography variant="body2" className="text-midLightGray ms-2">
            Quotation History
          </Typography>
        </Box>

        <CloseIcon className="cursor-pointer fill-midLightGray" onClick={() => setOpen(false)} />
      </Box>
      <Typography variant="h6" component="h6" className={styles.history__vendor__name}>
        {history[0].vendorName}
      </Typography>
    </Box>
  );
};
const HistoryContent = ({ history }: { history: QuotationObj[] }) => {
  return (
    <Box className="max-h-[368px] overflow-y-auto">
      {history.map(historyItem => (
        <Box className={styles.history__item__container}>
          <Box className="flex justify-between items-center py-1">
            <Typography variant="caption" className="text-midLightGray">
              {moment(historyItem?.createdAt).utc().format('MMMM DD, YYYY')}
            </Typography>

            <QuotationStatus status={historyItem.status} />
          </Box>

          <Link href={historyItem.url}>
            <Button
              variant="text"
              color="inherit"
              className="border-lightGray text-green"
              startIcon={<img src={PdfIcon} alt="" className="w-5" />}
              endIcon={<DownloadIcon fill="var(--green)" className="w-5" />}
              label="Download"
            />
          </Link>
        </Box>
      ))}
    </Box>
  );
};

export const HistoryModal = ({ open, setOpen, handlePrimaryClick, history }: Props) => {
  return (
    <Modal
      open={open}
      header={<HistoryHeader history={history} setOpen={setOpen} />}
      content={<HistoryContent history={history} />}
      handleClose={() => setOpen(false)}
      handlePrimary={handlePrimaryClick}
    />
  );
};
