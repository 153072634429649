import { cancelRfq } from 'services/orchestrator.service';
import { useMutation } from '@tanstack/react-query';

export const useCancelRfq = ({ refetch, setCancelModalOpen, token, id }) => {
  const { mutate: cancelRfqMutation, isPending: isCancelRfqPending } = useMutation({
    mutationKey: ['cancelRfq'],
    mutationFn: () => cancelRfq(token!, id!),
    onSuccess: () => {
      refetch();
      setCancelModalOpen(false);
    },
  });

  return {
    cancelRfqMutation,
    isCancelRfqPending,
  };
};
