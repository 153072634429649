import { ProductOverviewModelDto } from 'otl-codegen/dist/marketplace-frontend';
import { ProductCard } from 'design-system';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';

export const NopHits = ({ hits }: { hits: ProductOverviewModelDto[] | null }) => {
  return (
    <Grid container spacing={{ xs: 1.5, md: 3 }}>
      {hits?.map(product => (
        <Grid key={`nop-hit-${product.id}`} item xs={6} sm={4} md={3}>
          <Link to={`/product-details/${product.id}/${product.seName}`} state={{ fromSearch: false }}>
            <ProductCard
              key={product.id}
              pName={product.name}
              image={product.pictureModels![0].fullSizeImageUrl}
              price={product.productPrice.price}
              unit={product.unit}
            />
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};
