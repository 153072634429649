import { CategorySimpleModelDto } from 'otl-codegen/dist/marketplace-frontend';
import { getCatalogRoot } from 'services/orchestrator.service';
import { useQuery } from '@tanstack/react-query';

export const useCatalogRoot = () => {
  const { data: catalogRoot, isLoading } = useQuery<CategorySimpleModelDto[]>({
    queryKey: ['catalogRoot'],
    queryFn: async () => {
      const res = await getCatalogRoot();
      return res?.data?.filter(category => category.numberOfProducts !== 0);
    },
  });

  return {
    catalogRoot,
    isLoading,
  };
};
