import { Button, CheckedSuccessfully, Typography } from 'design-system';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

export const SubmitContactSuccess = () => {
  return (
    <Box className="bg-white p-12 max-w-[1120px] m-auto max-sm:bg-transparent max-sm:px-0 max-sm:pt-0">
      <>
        <Typography variant="h3" component="h3" className="text-darkGray">
          Contact Us
        </Typography>
        <Typography variant="body1" className="text-midLightGray">
          Let us know how we can help!
        </Typography>
      </>

      <Box className={styles.checked__container}>
        <Box className={styles.lottie__container}>
          <CheckedSuccessfully />
        </Box>
        <Typography variant="h4" component="h4" className="text-darkGray mb-2">
          Thank you for sending us
        </Typography>
        <Typography variant="body1" className="text-midLightGray text-center mb-4 leading-8">
          One of our Customer Service Associates will be assigned to your request and will get back to you soon
        </Typography>
        <Link to="/">
          <Button variant="text" color="deepYellow" label="Back to home" />
        </Link>
      </Box>
    </Box>
  );
};
