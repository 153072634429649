import { default as ReactPhoneInput, isValidPhoneNumber, Country } from 'react-phone-number-input';
import { ErrorFeedback } from '../../../typographies/error-feedback';
import { InputLabel } from '../../../typographies/input-label';
import { Controller, Control } from 'react-hook-form';
import { InputLabelProps } from '@mui/material';
import 'react-international-phone/style.css';
import 'react-phone-number-input/style.css';
import './styles.css';

export interface ControlledPhoneInputProps {
  name: string;
  control: Control<any>;
  inputLabelProps?: InputLabelProps;
  selectedCountry?: Country | undefined;
  handleCountryChange?: any;
}

export const ControlledPhoneInput = ({
  name,
  control,
  inputLabelProps,
  handleCountryChange,
  selectedCountry,
}: ControlledPhoneInputProps) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{ validate: isValidPhoneNumber }}
        render={({ field, fieldState }) => {
          const { onChange, value } = field;
          return (
            <>
              {inputLabelProps?.children && <InputLabel {...inputLabelProps} />}

              <ReactPhoneInput
                {...field}
                name={`${name}-${Math.random()}`}
                international
                countryCallingCodeEditable={false}
                value={value}
                onChange={onChange}
                defaultCountry={selectedCountry || 'EG'}
                onCountryChange={handleCountryChange && handleCountryChange}
                className={`${fieldState.error ? 'field-error' : 'field'}`}
              />

              {fieldState.error && <ErrorFeedback message={fieldState.error.message} />}
            </>
          );
        }}
      />
    </>
  );
};
