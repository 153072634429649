import { CatalogProductsModelDto } from 'otl-codegen/dist/marketplace-frontend';
import { getCategoryProducts } from 'services/orchestrator.service';
import { useCatalogRoot } from 'hooks/useCatalogRoot';
import { useEffect, useRef, useState } from 'react';
import { SwiperClass } from 'swiper/react';

export const useBrowseByCategory = () => {
  const { catalogRoot } = useCatalogRoot();

  const [categoryProductsResponse, setCategoryProductsResponse] = useState<{
    catalogProductsModel: CatalogProductsModelDto;
  }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [categoryId, setCategoryId] = useState<number | undefined>(catalogRoot && catalogRoot[0].id);
  const [categorySeName, setCategorySeName] = useState<string | undefined>('');
  const sliderRef = useRef<SwiperClass>();

  useEffect(() => {
    if (categoryId) {
      setLoading(true);
      getCategoryProducts({
        categoryId: +categoryId,
        body: {
          pageSize: 40,
          pageNumber: 1,
          orderBy: 5,
        },
      }).then(res => {
        setLoading(false);
        setCategoryProductsResponse(res?.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  useEffect(() => {
    setCategoryId(catalogRoot && catalogRoot[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogRoot]);

  return {
    categoryProductsResponse,
    setCategorySeName,
    categorySeName,
    catalogRoot,
    setCategoryId,
    categoryId,
    sliderRef,
    loading,
  };
};
