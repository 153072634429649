import { Typography, ControlledAutocompleteSelect, Loader, LoadingButton, ControlledTextField } from 'design-system';
import { ReactComponent as RfqArt3 } from 'assets/images/rfq-arts/third-step-rfq-art.svg';
import { ShipmentTermEnum } from 'services/types/generic/enums';
import { useSubmitRfqThirdStep } from './useSubmitRfqThirdStep';
import { Box, Divider, Grid, Hidden } from '@mui/material';
import { Stepper } from 'pages/rfq/components/stepper';
import styles from '../styles.module.scss';

export const RfqThirdStep = () => {
  const {
    onSubmit,
    control,
    countries,
    states,
    getValues,
    isValid,
    pageLoading,
    isPendingRfqUpdate,
    toListItems,
    paymentTerms,
  } = useSubmitRfqThirdStep();

  document.querySelector('#hubspot-messages-iframe-container')?.classList.add('translate-x-full');
  if (pageLoading) return <Loader />;
  return (
    <>
      <Box className={styles.rfq__container}>
        <Hidden mdDown>
          <Box className={styles.rfq__art}>
            <Typography variant="h6" className="text-violet">
              Great, you have reached your final step!
            </Typography>
            <RfqArt3 />
            <Typography variant="subtitle2" className="text-midDarkGray pt-3 max-w-[358px] text-center">
              Please choose your preferred shipping method and click submit.
            </Typography>
          </Box>
        </Hidden>

        <Box className={styles.rfq__form}>
          <Grid container spacing={{ xs: 2.5, md: 5 }} className="justify-center w-full">
            <Grid item xs={12} md={9}>
              <Stepper currentStep={3} />
            </Grid>

            <Hidden mdUp>
              <Grid item xs={12} md={9}>
                <Typography variant="h6" className="text-violet">
                  Great, you have reached your final step!
                </Typography>
              </Grid>
            </Hidden>

            <Grid item xs={12} md={9}>
              <ControlledAutocompleteSelect
                name="shipmentTerm"
                control={control}
                options={toListItems(ShipmentTermEnum)}
                placeholder="Select shipping terms"
                inputLabelProps={{ required: true, children: 'Shipping Terms' }}
              />
            </Grid>

            <Grid item xs={12} md={9}>
              <ControlledAutocompleteSelect
                name="country"
                control={control}
                options={countries}
                placeholder="Select shipping destination"
                inputLabelProps={{ required: true, children: 'Shipping Destination' }}
              />
            </Grid>

            <Grid item xs={12} md={9}>
              <ControlledAutocompleteSelect
                name="state"
                control={control}
                options={states}
                disabled={!getValues('country')}
                placeholder="Select shipping state"
                inputLabelProps={{ required: true, children: 'Shipping State', disabled: !getValues('country') }}
              />
            </Grid>

            <Grid item xs={12} md={9}>
              <ControlledTextField
                // changed from address to adrs to prevent browser autocomplete
                name="adrs"
                control={control}
                inputLabelProps={{ required: true, children: 'Full Address' }}
                textFieldProps={{
                  placeholder: 'Enter your full delivery information',
                  InputProps: { inputProps: { maxLength: 125 } },
                }}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <Divider className="pt-5" />
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ControlledTextField
                    name="targetUnitPrice"
                    control={control}
                    inputLabelProps={{ required: true, children: 'Target Unit Price' }}
                    textFieldProps={{
                      placeholder: 'Enter the price you prefer / unit',
                      type: 'number',
                      InputProps: {
                        inputProps: { min: 1, pattern: '\\d*' },
                        endAdornment: (
                          <Box className="flex items-center h-12 bg-white pe-3">
                            <Divider
                              orientation="vertical"
                              className="mr-2 h-8"
                              sx={{ borderColor: 'var(--lightGray)' }}
                            />
                            <Typography variant="caption">USD</Typography>
                          </Box>
                        ),
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ControlledAutocompleteSelect
                    name="paymentTerms"
                    control={control}
                    options={paymentTerms}
                    inputLabelProps={{ required: true, children: 'Payment Terms' }}
                    placeholder="Choose the payment terms you prefer"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={9}>
              <LoadingButton
                size="large"
                variant="contained"
                color="magentaPink"
                className="w-full"
                onClick={onSubmit}
                disabled={!isValid}
                loading={isPendingRfqUpdate}
                label="Next"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
