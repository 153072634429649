import { Clear, Search as SearchIcon } from '@mui/icons-material';
import { Box, TextField, TextFieldProps } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';

type SearchFieldProps = {
  onInputChange: any;
  endAdornment?: any;
  hasClear?: boolean;
  value?: any;
} & TextFieldProps;

export const SearchField = ({ onInputChange, endAdornment, hasClear, ...props }: SearchFieldProps) => {
  const [inputValue, setInputValue] = useState<any>();

  useEffect(() => {
    onInputChange(inputValue); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = event?.target?.value;
    setInputValue(newValue);
  };

  const StartAdornment = () => {
    return (
      <Box className=" pe-2">
        <SearchIcon />
      </Box>
    );
  };

  const EndAdornment = () => {
    return (
      <Box className="ps-2">
        <ClearItem />
      </Box>
    );
  };

  const ClearItem = () => {
    if (inputValue && inputValue.length > 0)
      return (
        <button onClick={() => setInputValue('')}>
          <Clear style={{ color: 'var(--body)' }} />
        </button>
      );
    else return <></>;
  };

  return (
    <TextField
      name={`SearchField-${Math.random()}`}
      variant="outlined"
      color="magentaPink"
      value={inputValue}
      onChange={handleInputChange}
      InputProps={{
        startAdornment: <StartAdornment />,
        endAdornment: hasClear ? <EndAdornment /> : endAdornment,
      }}
      {...props}
    />
  );
};
