import { ReactComponent as ShopIcon } from 'design-system/public/icons/shop.svg';
import { Link } from 'react-router-dom';
import { Button } from 'design-system';
import { sendEventToGTM } from 'utils';

export const BecomeASeller = () => {
  const backstoreURL = process.env.REACT_APP_BACKSTORE_BASE_URL;

  return (
    <Link to={`https://${backstoreURL}/redirecting-signup`}>
      <Button
        variant="text"
        color="inherit"
        className="text-midDarkGray"
        startIcon={<ShopIcon />}
        onClick={() => sendEventToGTM('become_seller')}
        label="Become a Seller"
      />
    </Link>
  );
};
