import { ReactComponent as CaretRight } from 'design-system/public/icons/caret-right.svg';
import { Swiper, SwiperClass, SwiperProps, SwiperSlide } from 'swiper/react';
import { CategoryCard, Loader, Typography } from 'design-system';
import { FreeMode, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { useRef } from 'react';

export const CategorySlider = ({ categoryDetails }) => {
  const subCategoriesLength = categoryDetails?.categoryModelDto.subCategories?.length;
  const sliderRef = useRef<SwiperClass>();

  const subCategoriesSwiperProps: SwiperProps = {
    direction: 'horizontal',
    spaceBetween: 16,
    slidesPerView: 'auto',
    modules: [FreeMode, Navigation],
    slideToClickedSlide: true,
    navigation: {
      prevEl: '.sub-categories.custom-swiper-button-prev',
      nextEl: '.sub-categories.custom-swiper-button-next',
      disabledClass: 'disabled-swiper',
    },
    onSwiper: swiper => {
      sliderRef.current = swiper;
    },
    loop: false,
  };

  if (!categoryDetails) return <Loader />;
  return (
    <>
      {categoryDetails.categoryModelDto.subCategories && subCategoriesLength! > 0 && (
        <Box className="mb-6 max-sm:mb-3">
          <Typography variant="subtitle1" className="mb-6 max-sm:mb-3 text-darkGray">
            Categories
          </Typography>

          <div className="swiper-with-arrows">
            <div className="category-swiper custom-swiper-button-prev" onClick={() => sliderRef?.current?.slidePrev()}>
              <CaretRight />
            </div>
            <Swiper {...subCategoriesSwiperProps}>
              {categoryDetails.categoryModelDto.subCategories.map(subCategory => (
                <SwiperSlide style={{ width: 'auto' }} key={`sub-category-${subCategory.id}`}>
                  <Link to={`/category/${subCategory.id}/${subCategory.seName}`}>
                    <CategoryCard
                      category={{
                        id: subCategory.id,
                        name: subCategory.name || '',
                        pictureModel: {
                          fullSizeImageUrl: subCategory.pictureModel.fullSizeImageUrl || '',
                          alternateText: subCategory.pictureModel.alternateText || '',
                        },
                      }}
                    />
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>

            <div className="category-swiper custom-swiper-button-next" onClick={() => sliderRef?.current?.slideNext()}>
              <CaretRight />
            </div>
          </div>
        </Box>
      )}
    </>
  );
};
