import { TierPriceModelDto } from 'otl-codegen/dist/marketplace-frontend';

const formulateTierPriceCurrency = (price: string) => {
  const currency = price.substring(0, 3);
  return currency;
};

export const formulateTierPrices = (
  tierPrices: TierPriceModelDto[] | null,
): (TierPriceModelDto & { tierRange: string; currency: string })[] => {
  if (!tierPrices) return [];
  const tierPricesFinal = tierPrices.map((tierPrice, i) => {
    if (i === 0)
      return {
        ...tierPrice,
        tierRange: `1 - ${tierPrice.quantity}`,
        currency: formulateTierPriceCurrency(tierPrice.price!),
      };
    if (i === tierPrices.length - 1)
      return {
        ...tierPrice,
        tierRange: `>= ${tierPrices[i - 1].quantity}`,
        currency: formulateTierPriceCurrency(tierPrice.price!),
      };
    return {
      ...tierPrice,
      tierRange: `${tierPrices[i - 1].quantity + 1} - ${tierPrice.quantity}`,
      currency: formulateTierPriceCurrency(tierPrice.price!),
    };
  });
  return tierPricesFinal;
};
