import { LoadingButton as MuiLoadingButton, LoadingButtonProps as MuiLoadingButtonProps } from '@mui/lab';

export interface LoadingButtonProps extends MuiLoadingButtonProps {
  label: string;
}

export const LoadingButton = ({ label, color = 'magentaPink', variant = 'contained', ...rest }: LoadingButtonProps) => {
  return (
    <MuiLoadingButton color={color} variant={variant} {...rest}>
      {label}
    </MuiLoadingButton>
  );
};
