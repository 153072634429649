import {
  categoriesPlugin,
  productsPlugin,
  vendorsPlugin,
  popularCategoriesPlugin,
  popularPlugin,
  recentSearchesPlugin,
  querySuggestionsPlugin,
} from './plugins';
import {
  KeywordSuggestion,
  PopularSearches,
  RecentSearches,
  SectionHeader,
  Products,
  // Categories,
  // Vendors,
  PopularCategories,
} from './components';
import { AutocompleteOptions, createAutocomplete, AutocompleteState } from '@algolia/autocomplete-core';
import { ReactComponent as SearchIcon } from 'design-system/public/icons/search.svg';
import { createRedirectUrlPlugin } from '@algolia/autocomplete-plugin-redirect-url';
import { ReactComponent as RFQIcon } from 'design-system/public/icons/rfq.svg';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { getCollectionBySourceId } from './functions';
import { Button, Typography } from 'design-system';
import { ProductHit } from 'types/ProductHit';
import { Clear } from '@mui/icons-material';
import styles from './styles.module.scss';

type AlgoliaAutocompleteProps = {
  props?: Partial<AutocompleteOptions<ProductHit>>;
};

export const AlgoliaAutocomplete = ({ props }: AlgoliaAutocompleteProps) => {
  const [autocompleteState, setAutocompleteState] = useState<AutocompleteState<any>>({
    collections: [],
    completion: null,
    context: {},
    isOpen: false,
    query: '',
    activeItemId: null,
    status: 'idle',
  });

  const navigate = useNavigate();
  const location = useLocation();

  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [deletedItems, setDeletedItems] = useState<any[]>([]);
  const [isInputFocused, setInputFocused] = useState(false);

  const inputRef = useRef<any>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);

  const panelDiv = document.getElementById('panel');
  const outletDiv = document.getElementById('router-outlet');
  const footerDiv = document.getElementById('footer-box');

  const redirectUrlPlugin = createRedirectUrlPlugin();
  const recentSearches = getCollectionBySourceId(autocompleteState, 'recentSearchesPlugin');
  const filteredItems = recentSearches && recentSearches.items.filter(item => !deletedItems.includes(item.id));
  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  const autocomplete = useMemo(
    () =>
      createAutocomplete<ProductHit, React.BaseSyntheticEvent, React.MouseEvent, React.KeyboardEvent>({
        openOnFocus: true,
        insights: true,
        placeholder: 'What are you looking for?',
        plugins: [
          redirectUrlPlugin,
          recentSearchesPlugin,
          popularPlugin,
          querySuggestionsPlugin,
          productsPlugin,
          categoriesPlugin,
          vendorsPlugin,
          popularCategoriesPlugin,
        ],
        initialState: { query: searchKeyword },
        onStateChange({ state }) {
          setAutocompleteState(state);
        },
        onSubmit: params => {
          if (params.state.query.trim() !== '') {
            navigate(`/products?search=${params.state.query}`, { state: { searchName: params.state.query } });
          }
        },
        ...props,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props],
  );

  useEffect(() => {
    const searchBox = document.getElementById('search-box');
    const panelDiv = document.getElementById('panel');
    const height = searchBox?.offsetHeight;
    if (panelDiv) panelDiv.style.top = `calc(${height}px + var(--panel-margin-top))`;
  }, []);

  useEffect(() => {
    const keyword = location.state?.searchName;
    if (keyword) {
      autocomplete.setQuery(keyword);
      setSearchKeyword(keyword);
    } else if (location.state?.fromSearch) {
      autocomplete.setQuery(location.state?.hitName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, location.state, searchKeyword]);

  useEffect(() => {
    inputRef.current.blur();
    panelDiv?.classList.add('hidden');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const hubspotMessagesContainer = document.querySelector('#hubspot-messages-iframe-container');
    if (hubspotMessagesContainer) {
      if (autocompleteState.isOpen) {
        hubspotMessagesContainer.classList.add('translate-x-full');
      } else {
        hubspotMessagesContainer.classList.remove('translate-x-full');
      }
    }
  }, [autocompleteState.isOpen]);

  useEffect(() => {
    if (!formRef.current || !panelRef.current || !inputRef.current) {
      return undefined;
    }
    const { onTouchStart, onTouchMove, onMouseDown } = autocomplete.getEnvironmentProps({
      formElement: formRef.current,
      inputElement: inputRef.current,
      panelElement: panelRef.current,
    });
    window.addEventListener('mousedown', onMouseDown);
    window.addEventListener('touchstart', onTouchStart);
    window.addEventListener('touchmove', onTouchMove);

    return () => {
      window.removeEventListener('mousedown', onMouseDown);
      window.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('touchmove', onTouchMove);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autocomplete.getEnvironmentProps, autocompleteState.isOpen]);

  useEffect(() => {
    if (isInputFocused) panelDiv?.classList.remove('hidden');
    if (isDownMd && outletDiv && footerDiv && panelDiv) {
      if (isInputFocused) {
        panelDiv.style.visibility = 'visible';
        outletDiv.style.visibility = 'hidden';
        footerDiv.style.visibility = 'hidden';
      } else {
        // panelDiv.style.visibility = 'hidden';
        outletDiv.style.visibility = 'visible';
        footerDiv.style.visibility = 'visible';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInputFocused]);

  const removeItem = (e, id: string) => {
    e.stopPropagation();
    recentSearchesPlugin.data?.removeItem(id);
    setDeletedItems(prev => [...prev, id]);
  };

  const onPressClear = () => {
    if (formRef && formRef.current) {
      formRef.current.reset();
    }
    if (inputRef && inputRef?.current) {
      inputRef.current.value = '';
    }
    inputRef.current.blur();
    setInputFocused(false);

    if (isDownMd && panelDiv && outletDiv && footerDiv) {
      panelDiv.style.visibility = 'hidden';
      outletDiv.style.visibility = 'visible';
      footerDiv.style.visibility = 'visible';
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      if (formRef && formRef.current) {
        formRef.current.requestSubmit();
        event.preventDefault();
      }

      if (autocompleteState.query.trim() !== '') {
        navigate(`/products?search=${autocompleteState.query}`, { state: { searchName: autocompleteState.query } });
      }

      if (isDownMd) {
        inputRef.current.blur();
        setInputFocused(false);
      }
    }
  };

  const onPressSearch = () => {
    const query = inputRef?.current.value;
    if (query.trim().length > 0) navigate(`/products?search=${query}`, { state: { searchName: query } });
  };

  const sourceIdsToExclude = ['popularPlugin', 'popularCategoriesPlugin'];
  const hasResults =
    autocompleteState.collections
      .filter(({ source }) => sourceIdsToExclude.indexOf(source.sourceId) === -1)
      .reduce((prev, curr) => prev + curr.items.length, 0) > 0;

  return (
    <>
      <div {...autocomplete.getRootProps({})} className={styles.search__box} id="search-box">
        <form
          ref={formRef}
          className="aa-Form"
          {...autocomplete.getFormProps({
            inputElement: inputRef.current,
          })}
        >
          <div className="aa-InputWrapper">
            <input
              className="aa-Input"
              ref={inputRef}
              {...autocomplete.getInputProps({
                inputElement: inputRef.current,
              })}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              onKeyDown={handleKeyDown}
            />
          </div>

          <div className="aa-InputWrapperSuffix">
            {inputRef.current?.value && inputRef.current?.value !== '' && (
              <button className="aa-ClearButton" onClick={() => onPressClear()}>
                <Clear className="text-midLightGray" />
              </button>
            )}
          </div>

          <button onClick={() => onPressSearch()} className={styles.search__button}>
            <SearchIcon fill="var(--white)" color="unset" />
          </button>
        </form>

        <div
          ref={panelRef}
          id="panel"
          className={[
            'aa-Panel',
            isDownMd ? '' : 'aa-Panel--desktop',
            autocompleteState.status === 'stalled' && 'aa-Panel--stalled',
          ]
            .filter(Boolean)
            .join(' ')}
          hidden={(!autocompleteState.query && !hasResults) || !autocompleteState.isOpen}
          {...autocomplete.getPanelProps({})}
        >
          <div className="aa-PanelLayout aa-Panel--scrollable">
            <div className="aa-PanelSections">
              {!autocompleteState.query && (
                <div className="w-full">
                  {filteredItems && filteredItems.length > 0 && (
                    <>
                      <SectionHeader title="Quick search" />
                      <RecentSearches
                        autocomplete={autocomplete}
                        autocompleteState={autocompleteState}
                        filteredItems={filteredItems}
                        removeItem={removeItem}
                      />
                    </>
                  )}
                  <PopularSearches autocomplete={autocomplete} autocompleteState={autocompleteState} />
                </div>
              )}

              {autocompleteState.query && (
                <>
                  <div className="aa-PanelSection--left">
                    {hasResults && (
                      <>
                        <SectionHeader title="Suggestions" />
                        <RecentSearches
                          autocomplete={autocomplete}
                          autocompleteState={autocompleteState}
                          filteredItems={filteredItems}
                          removeItem={removeItem}
                        />
                        <KeywordSuggestion autocomplete={autocomplete} autocompleteState={autocompleteState} />
                        {/* <Categories autocomplete={autocomplete} autocompleteState={autocompleteState} /> */}
                        {/* <Vendors autocomplete={autocomplete} autocompleteState={autocompleteState} /> */}
                      </>
                    )}
                    {!hasResults && (
                      <div className="aa-NoResultsQuery">
                        <Typography variant="h5" component="h5">
                          No results for "{autocompleteState.query}"
                        </Typography>

                        <div className="aa-NoResultsAdvices">
                          <ul className="aa-NoResultsAdvicesList">
                            <li>
                              <Typography variant="subtitle2">Double check your spelling</Typography>
                            </li>
                            <li>
                              <Typography variant="subtitle2">Use fewer keywords</Typography>
                            </li>
                            <li>
                              <Typography variant="subtitle2">Search for a less specific item</Typography>
                            </li>
                            <li>
                              <Typography variant="subtitle2">
                                Try navigate using on the of the popular categories
                              </Typography>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="aa-PanelSection--right">
                    {hasResults && <Products autocomplete={autocomplete} autocompleteState={autocompleteState} />}

                    {!hasResults && (
                      <PopularCategories autocomplete={autocomplete} autocompleteState={autocompleteState} />
                    )}
                  </div>
                </>
              )}
            </div>

            {autocompleteState.query && !hasResults && (
              <div className="w-full flex flex-col items-center mt-6">
                <Typography variant="subtitle2" className="mb-3 text-midDarkGray">
                  Not finding what you're looking for? Send a request for quotation
                </Typography>

                <Button
                  size={isDownMd ? 'small' : 'medium'}
                  onClick={() => navigate('/rfq/submit/first-step')}
                  startIcon={<RFQIcon stroke="var(--white)" width={24} height={24} />}
                  label="Request for Quotation"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
