let data = [
  {
    values: {
      title: 'Variety of industries & suppliers',
      description:
        '<ul style="list-style-type: disc;"><li> Access to a wide variety of products in one place.</li> <li>  Simplifies sourcing and saves time.</li></ul>',
    },
  },
  {
    values: {
      title: 'Trusted marketplace',
      description:
        ' <ul style="list-style-type: disc;"><li>Provides assurance of product quality and seller reliability. </li> <li> Secure transactions: Protects payments and ensures delivery of goods. </li> <li>  Ability to negotiate directly with sellers: Enables flexibility in pricing and terms.</li></ul>',
    },
  },
  {
    values: {
      title: 'Competitive prices',
      description:
        '<ul style="list-style-type: disc;"><li>30% cost saving from your local market. </li> <li>  Compare product prices.</li></ul>',
    },
  },
  {
    values: {
      title: 'Customize your request',
      description:
        '<ul style="list-style-type: disc;"><li> Tailor your orders to meet your business needs. </li> <li> Our team will submit different quotations from various suppliers for you to find the best attainable price. </li></ul>',
    },
  },
];

export const aboutOtrovatoData = {
  total: data.length,
  results: data,
};
