import {
  Typography,
  Listing,
  Pagination,
  Loader,
  StatusChip,
  Button,
  getFileExtension,
  IconButton,
  isImageFormat,
} from 'design-system';
import { Box, Divider, Card, TableCell, TableRow, Hidden, Menu, MenuItem } from '@mui/material';
import { ReactComponent as RFQIcon } from 'design-system/public/icons/rfq.svg';
import LogoGrayScale from 'design-system/public/images/logo-Grayscale.svg';
import { RfqTableToolbar } from '../components/rfq-table-toolbar';
import { CancelModal } from '../components/modals/cancel-modal';
import { RfqStatusEnum } from 'services/types/generic/enums';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { RFQ } from 'services/types/generic/rfq-entity';
import { MoreHoriz } from '@mui/icons-material';
import styles from './styles.module.scss';
import { useRfqList } from './useRfqList';
import { HasNoResult } from 'components';
import { Link } from 'react-router-dom';
import moment from 'moment';
import React from 'react';

export const RFQList = () => {
  const {
    myRfq,
    orderBy,
    setOrderBy,
    setSearchQuery,
    onChangePage,
    handleViewDetails,
    setSelectedItem,
    isRfqListFetching,
    isCancelRfqPending,
    cancelModalOpen,
    setCancelModalOpen,
    handleCancelRfq,
    headerColumns,
    selectColumns,
    showHasNoSearchResult,
    showHasNoTableData,
    showPagination,
    showTableData,
    tableContainerHeight,
    count,
    pageNumber,
  } = useRfqList();

  const renderIndexColumn = ({ rfqItem, select }: { rfqItem: RFQ; select: string }) => {
    let mainFile: { fileName: string; url: string } | undefined;
    if (rfqItem.files && rfqItem.files[0]) mainFile = rfqItem.files[0];

    return (
      <Box className="flex items-center px-10">
        <Box className="file__container">
          {mainFile && (
            <>
              {isImageFormat(getFileExtension(mainFile.fileName)) && <img src={mainFile.url} alt={mainFile.fileName} />}
              {!isImageFormat(getFileExtension(mainFile.fileName)) && (
                <FileIcon
                  extension={getFileExtension(mainFile.fileName)}
                  {...defaultStyles[getFileExtension(mainFile.fileName)]}
                />
              )}
            </>
          )}

          {!(rfqItem && mainFile) && <img src={LogoGrayScale} alt="logo-grayscale" />}
        </Box>
        <Box className="flex flex-col items-start">
          <Typography variant="caption" className="text-darkGray font-bold line-clamp-1">
            {rfqItem[select]}
          </Typography>

          <Typography variant="body2" className="text-midLightGray">
            {`${rfqItem?.quantity} / ${rfqItem?.unit}`}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderOtherCells = ({ rfqItem, select }: { rfqItem: RFQ; select: string }) => {
    if (select === 'status')
      return (
        <Box className="flex justify-center">
          <StatusChip
            status={rfqItem.status}
            sx={{
              width: '83px',
              height: '28px',
            }}
          />
        </Box>
      );
    return (
      <Typography variant="body2" className="text-midLightGray">
        {rfqItem[select]}
      </Typography>
    );
  };

  const HeaderRow = () => {
    return (
      <>
        {headerColumns.map((column, idx) => (
          <TableCell
            key={`products-listing-header-cell-${idx}`}
            sx={{ color: showHasNoTableData ? 'var(--gray)' : '' }}
          >
            {column}
          </TableCell>
        ))}
      </>
    );
  };

  const TableBody = () => {
    return (
      <>
        {myRfq &&
          myRfq.data.map((rfqItem, idx) => (
            <TableRow key={`products-table-row-${idx}`}>
              {selectColumns.map((select, idx) => (
                <TableCell key={`products-table-cell-${idx}`}>
                  {idx === 0 && renderIndexColumn({ rfqItem, select })}
                  {idx !== 0 && renderOtherCells({ rfqItem, select })}
                </TableCell>
              ))}

              <TableCell>
                <Button
                  size="small"
                  variant="outlined"
                  color="violet"
                  onClick={() => handleViewDetails(rfqItem.id)}
                  className="w-[92px]"
                  label="View"
                />
                <ThreeDotsMenu rfqItem={rfqItem} />
              </TableCell>
            </TableRow>
          ))}
      </>
    );
  };

  const ThreeDotsMenu = ({ rfqItem }: { rfqItem: RFQ }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const anchorRef = React.useRef(null);

    const handleMenuItemClick = () => {
      setSelectedItem(rfqItem);
      setCancelModalOpen(true);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <>
        <IconButton
          ref={anchorRef}
          onClick={handleClick}
          size="small"
          className="ms-11"
          aria-haspopup="true"
          aria-controls={open ? `table-menu-${rfqItem.id}` : undefined}
          aria-expanded={open ? 'true' : undefined}
          disabled={rfqItem.status === RfqStatusEnum.Closed}
          icon={
            <MoreHoriz
              sx={{
                color: rfqItem.status === RfqStatusEnum.Closed ? 'var(--midLightGray)' : 'var(--darkGray)',
              }}
            />
          }
        />
        <Menu
          id={`table-menu-${rfqItem.id}`}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            style: {
              width: 125,
            },
          }}
          disableScrollLock
        >
          <MenuItem onClick={handleMenuItemClick}>Close</MenuItem>
        </Menu>
      </>
    );
  };

  const ResponsiveListItem = ({ rfqItem }: { rfqItem: RFQ }) => {
    return (
      <Card className={styles.rfq__card} onClick={() => handleViewDetails(rfqItem.id)}>
        <Typography variant="subtitle2" className="text-darkGray mb-1">
          {rfqItem.productName}
        </Typography>

        <Typography variant="body2" className="text-midLightGray">
          {`${rfqItem.quantity} / ${rfqItem.unit}`}
        </Typography>

        <Divider className="my-4" />

        <Box className="flex items-center justify-between mb-3">
          <Typography variant="body1" className="text-midLightGray">
            Status
          </Typography>
          <StatusChip
            status={rfqItem?.status}
            sx={{
              width: { xs: '74px', md: '94px' },
              height: { xs: '28px', md: '34px' },
            }}
          />
        </Box>

        <Box className="flex items-center justify-between mb-3">
          <Typography variant="body1" className="text-midLightGray">
            Creation Date
          </Typography>
          <Typography variant="body2" className="text-darkGray">
            {moment(rfqItem.CreatedAt).utc().format('MMMM DD, YYYY')}
          </Typography>
        </Box>

        <Box className="flex items-center justify-between">
          <Typography variant="body1" className="text-midLightGray">
            Expiration Date
          </Typography>
          <Typography variant="body2" className="text-darkGray">
            {moment(rfqItem.expDate).utc().format('MMMM DD, YYYY')}
          </Typography>
        </Box>
      </Card>
    );
  };

  return (
    <>
      <CancelModal
        open={cancelModalOpen}
        handlePrimaryClick={handleCancelRfq}
        handleClose={() => setCancelModalOpen(false)}
        loading={isCancelRfqPending}
      />

      {/* Web */}
      <Hidden mdDown>
        <Listing
          tableHeader={<HeaderRow />}
          tableBody={<TableBody />}
          tableToolbar={
            <RfqTableToolbar
              setSearchQuery={setSearchQuery}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              disabled={showHasNoTableData}
            />
          }
          showPagination={showPagination}
          tablePagination={
            <>{myRfq && <Pagination count={count} page={pageNumber} handleChangePage={onChangePage} />}</>
          }
          tableContainerProps={{ sx: { height: tableContainerHeight } }}
          loadingData={isRfqListFetching}
          showTableData={showTableData}
          showHasNoTableData={showHasNoTableData}
          hasNoTableData={
            <HasNoResult
              title="Ready to take the next step?"
              description="Submit an RFQ with all the details of the products you're seeking, and we'll contact you!"
              buttonComponent={
                <Link to="/rfq/submit/first-step" id="CreateRFQLink">
                  <Button
                    size="small"
                    className="w-36"
                    startIcon={<RFQIcon stroke="var(--white)" width={24} height={24} />}
                    label="Create RFQ"
                  />
                </Link>
              }
            />
          }
          hasNoSearchResults={
            <HasNoResult
              title="It seems like your search is not available right now!"
              description=""
              buttonComponent={
                <Link to="/rfq/submit/first-step" id="CreateRFQLink">
                  <Button
                    size="small"
                    className="w-36 hidden"
                    startIcon={<RFQIcon stroke="var(--white)" width={24} height={24} />}
                    label="Create RFQ"
                  />
                </Link>
              }
            />
          }
          showHasNoSearchResults={showHasNoSearchResult}
        />
      </Hidden>

      {/* Responsive */}
      <Hidden mdUp>
        <RfqTableToolbar
          setSearchQuery={setSearchQuery}
          setOrderBy={setOrderBy}
          orderBy={orderBy}
          disabled={showHasNoTableData}
        />

        {isRfqListFetching && <Loader />}

        {!isRfqListFetching && (
          <>
            {myRfq && (myRfq.count ?? 0) > 0 && (
              <Box className="px-5">
                {myRfq &&
                  myRfq.data.map(rfqItem => (
                    <ResponsiveListItem key={`responsive-list-rfqItem${rfqItem.id}`} rfqItem={rfqItem} />
                  ))}
              </Box>
            )}

            {showPagination && myRfq && (myRfq.count ?? 0) > 0 && (
              <Box className="my-10">
                <Pagination count={count} page={pageNumber} handleChangePage={onChangePage} />
              </Box>
            )}
          </>
        )}

        {showHasNoTableData && (
          <HasNoResult
            title="Ready to take the next step?"
            description="Submit an RFQ with all the details of the products you're seeking, and
          we'll contact you!"
            buttonComponent={
              <Link to="/rfq/submit/first-step" id="CreateRFQLink">
                <Button
                  size="small"
                  className="w-36"
                  startIcon={<RFQIcon stroke="var(--white)" width={24} height={24} />}
                  label="Create RFQ"
                />
              </Link>
            }
          />
        )}

        {showHasNoSearchResult && (
          <HasNoResult
            title="It seems like your search is not available right now!"
            description=""
            buttonComponent={
              <Link to="/rfq/submit/first-step" id="CreateRFQLink">
                <Button
                  size="small"
                  className="w-36 hidden"
                  startIcon={<RFQIcon stroke="var(--white)" width={24} height={24} />}
                  label="Create RFQ"
                />
              </Link>
            }
          />
        )}
      </Hidden>
    </>
  );
};
