import { QuotationsResponse } from 'services/dtos/quotation/quotations.response';
import { RfqStatusEnum } from 'services/types/generic/enums';
import { RFQ } from 'services/types/generic/rfq-entity';

export const countNewQuotationStatus = ({
  rfqDetails,
  quotations,
}: {
  rfqDetails: RFQ;
  quotations: QuotationsResponse;
}) => {
  if (rfqDetails.status !== RfqStatusEnum.Active) return 0;
  return Object.values(quotations)
    .filter(Array.isArray)
    .flat()
    .reduce((count, item) => count + (item.status === 'New' ? 1 : 0), 0);
};
