import { CatalogProductsModelDto, SelectListItemDto } from 'otl-codegen/dist/marketplace-frontend';
import { getVendorProducts } from 'services/orchestrator.service';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const useVendorProducts = () => {
  const [productsResponse, setProductsResponse] = useState<{
    catalogProductsModel: CatalogProductsModelDto;
  }>();

  const [pageSize, setPageSize] = useState<number>(40);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<number>(5);
  const [priceRange, setPriceRange] = useState<number[]>([0, 10000]);
  const { vendorId } = useParams();
  const itemsCount = productsResponse?.catalogProductsModel.totalItems;
  const pagesCount = productsResponse?.catalogProductsModel.totalPages;

  const pageSizeOptions: SelectListItemDto[] = [
    {
      disabled: false,
      group: null,
      selected: true,
      text: '40',
      value: '40',
    },
    {
      disabled: false,
      group: null,
      selected: false,
      text: '60',
      value: '60',
    },
    {
      disabled: false,
      group: null,
      selected: false,
      text: '100',
      value: '100',
    },
  ];
  const availableSortOptions: SelectListItemDto[] = [
    {
      disabled: false,
      group: null,
      selected: false,
      text: 'Name: A to Z',
      value: '5',
    },
    {
      disabled: false,
      group: null,
      selected: false,
      text: 'Name: Z to A',
      value: '6',
    },
    {
      disabled: false,
      group: null,
      selected: false,
      text: 'Price: Low to High',
      value: '10',
    },
    {
      disabled: false,
      group: null,
      selected: false,
      text: 'Price: High to Low',
      value: '11',
    },
    {
      disabled: false,
      group: null,
      selected: false,
      text: 'Created on',
      value: '15',
    },
  ];

  const handleFilterRequest = () => {
    if (vendorId)
      getVendorProducts({
        vendorId: +vendorId,
        body: {
          price: `${priceRange[0]} - ${priceRange[1]}`,
          pageSize,
          pageNumber,
          orderBy,
        },
      }).then(res => {
        setProductsResponse(res?.data);
        setPageNumber(res?.data?.catalogProductsModel?.pageNumber);
        const rangeFrom = res?.data?.catalogProductsModel?.priceRangeFilter?.availablePriceRange?.from;
        const rangeTo = res?.data?.catalogProductsModel?.priceRangeFilter?.availablePriceRange?.to;
        rangeFrom && rangeTo && setPriceRange([rangeFrom, rangeTo]);
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can change this to 'auto' if you want instant scrolling
          });
        }, 150);
      });
  };

  useEffect(() => {
    if (vendorId)
      getVendorProducts({
        vendorId: +vendorId,
        body: {
          price: `${priceRange[0]} - ${priceRange[1]}`,
          pageSize,
          pageNumber,
          orderBy,
        },
      }).then(res => {
        setProductsResponse(res?.data);

        const rangeFrom = res?.data?.catalogProductsModel?.priceRangeFilter?.availablePriceRange?.from;
        const rangeTo = res?.data?.catalogProductsModel?.priceRangeFilter?.availablePriceRange?.to;
        setPriceRange([rangeFrom!, rangeTo!]);

        setPageNumber(res?.data?.catalogProductsModel?.pageNumber);
      });
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // You can change this to 'auto' if you want instant scrolling
      });
    }, 150);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorId, pageSize, pageNumber, orderBy]);

  return {
    vendorId,
    categoryProductsResponse: productsResponse,
    itemsCount,
    pagesCount,

    pageSize,
    pageSizeOptions,
    setPageSize: (val: number) => {
      setPageSize(val);
    },

    orderBy,
    availableSortOptions,
    setOrderBy: (val: number) => {
      setOrderBy(val);
    },

    pageNumber,
    handleChangePage: (val: number) => {
      setPageNumber(val);
    },

    priceRange,
    setPriceRange: (event: Event, newValue: number | number[]) => {
      setPriceRange(newValue as number[]);
    },

    handleFilterRequest: () => {
      handleFilterRequest();
    },
  };
};
