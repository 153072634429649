import { useMessageCenterContext } from 'contexts/message-center-context';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { useGetVendorById } from 'hooks/useGetVendorById';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { htmlToText, sendEventToGTM } from 'utils';
import { useEffect, useState } from 'react';
import { useCustomAuth } from 'hooks';

export const useVendorDetails = () => {
  const { vendorId } = useParams();
  const navigate = useNavigate();
  const { handleContactSeller, currentChannelUrl } = useMessageCenterContext();
  const { isAuthenticated, loginWithRedirect } = useCustomAuth();
  const { updateBreadcrumbs } = useBreadcrumbs();

  const [contactSellerClicked, setContactSellerClicked] = useState(false);
  const [descriptionTextResp, setDescriptionTextResp] = useState<string | null | undefined>();
  const [fullDescriptionOpen, setFullDescriptionOpen] = useState<boolean>(false);

  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));
  const { isVendorDetailsLoading, vendorDetails, isErrorVendorDetails } = useGetVendorById({
    vendorId: +vendorId!,
  });

  useEffect(() => {
    updateBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (vendorDetails) {
      sendEventToGTM('view_vendor_profile', {
        vendor_id: vendorDetails?.id,
        vendor_name: vendorDetails?.name,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorDetails?.id]);

  useEffect(() => {
    vendorDetails?.description &&
      setDescriptionTextResp(htmlToText(vendorDetails?.description)?.substring(0, 65).concat('...'));
  }, [vendorDetails]);

  useEffect(() => {
    if (vendorDetails?.description)
      if (fullDescriptionOpen) {
        setDescriptionTextResp(htmlToText(vendorDetails?.description));
      } else setDescriptionTextResp(htmlToText(vendorDetails?.description)?.substring(0, 65).concat('...'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullDescriptionOpen]);

  useEffect(() => {
    if (vendorDetails) {
      updateBreadcrumbs([
        {
          label: `${vendorDetails.name}`,
          link: `vendor-details/${vendorDetails?.id}`,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorDetails]);

  useEffect(() => {
    if (isDownMd && currentChannelUrl !== '' && contactSellerClicked) {
      navigate(`/channels/${currentChannelUrl}`);
      setContactSellerClicked(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChannelUrl, contactSellerClicked]);

  const onContactSeller = () => {
    if (isAuthenticated && vendorDetails) {
      setContactSellerClicked(true);
      const message = 'Hi, I am interested in some products you listed and need more details.';
      handleContactSeller({
        message,
        vendorName: vendorDetails.name || '',
        vendorRepresentativeEmail: vendorDetails.email || '',
        vendorId: vendorDetails.id,
      });
      sendEventToGTM('vendor_details_page_negotiate', {
        vendor_id: vendorDetails.id,
        vendor_name: vendorDetails.name,
      });
    } else loginWithRedirect({ redirectUri: window.location.pathname });
  };

  return {
    vendorDetails,
    onContactSeller,
    isDownMd,
    descriptionTextResp,
    fullDescriptionOpen,
    setFullDescriptionOpen,
    isVendorDetailsLoading,
    isErrorVendorDetails,
  };
};
