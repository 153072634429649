import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';

export interface ButtonProps extends MuiButtonProps {
  label: string;
}

export const Button = ({ color = 'magentaPink', variant = 'contained', label, ...rest }: ButtonProps) => {
  return (
    <MuiButton color={color} variant={variant} {...rest}>
      {label}
    </MuiButton>
  );
};
