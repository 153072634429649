import { Button, ErrorCone, Typography } from 'design-system';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

export const ErrorPage = () => {
  return (
    <Box className="mt-6">
      <Box className={styles.checked__container}>
        <Box className={styles.lottie__container}>
          <ErrorCone />
        </Box>
        <Box className={styles.info}>
          <Typography variant="h4" component="h4" className="text-magentaPink mb-2 max-md:mb-0">
            Oops!
          </Typography>

          <Typography variant="h6" component="h6" className="text-darkGray mb-2 max-md:mb-0">
            looks like something went wrong
          </Typography>

          <Link to="/">
            <Button variant="text" color="deepYellow" label="Back to home" />
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
