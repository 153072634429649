import { Auth0GetUser } from 'services/dtos/auth/Auth0GetUser.dto';
import { getUser } from 'services/orchestrator.service';
import { useQuery } from '@tanstack/react-query';
import { useAuthToken } from './useAuthToken';

export const useMyUser = () => {
  const { token } = useAuthToken();

  const setInitialData = () => {
    try {
      const storedUser = localStorage.getItem('myUser');
      return storedUser ? JSON.parse(storedUser) : null;
    } catch (error) {
      console.error('Error parsing user from localStorage:', error);
      return null;
    }
  };

  const {
    data: myUser,
    refetch,
    isError,
    error,
  } = useQuery<Auth0GetUser | null>({
    queryKey: ['myUser', token],
    queryFn: async () => {
      const res = await getUser(token);
      const fetchedUser = res?.data[0];
      localStorage.setItem('myUser', JSON.stringify(fetchedUser));
      return fetchedUser;
    },
    enabled: !!token,
    initialData: setInitialData,
  });

  if (isError) {
    console.error('Error fetching user:', error);
  }

  return { myUser, refetch };
};
