import { ProductDetailsModelDto, VendorModelDto } from 'otl-codegen/dist/marketplace-frontend';
import { VendorCardActions } from '../vendor-card-actions';
import styles from './styles.module.scss';
import { HTMLAttributes } from 'react';
import { Card } from '@mui/material';

type Props = {
  vendorDetails: VendorModelDto;
  productDetails: ProductDetailsModelDto;
} & HTMLAttributes<HTMLDivElement>;

export const ProductVendorCardResponsive = ({ vendorDetails, productDetails, ...props }: Props) => {
  return (
    <div {...props} className="z-[999] sticky left-0 bottom-14 w-screen -ms-5 mt-6">
      <Card key={`Vendor ${vendorDetails?.id}`} className={styles.vendor__card__responsive}>
        <div className={styles.vendor__card__responsive__container}>
          <img
            src={vendorDetails.pictureModel.fullSizeImageUrl || ''}
            alt={vendorDetails.pictureModel.alternateText || ''}
          />

          <VendorCardActions
            vendorDetails={vendorDetails}
            productDetails={productDetails}
            secondaryButtonText="View Profile"
          />
        </div>
      </Card>
    </div>
  );
};
