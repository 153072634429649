import { getRfqDetails, getRfqFiles, getBuyerRfqQuotations } from 'services/orchestrator.service';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { RFQFile } from 'services/types/rfq/RfqFile.type';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useAuthToken, useCancelRfq } from 'hooks';
import { useQuery } from '@tanstack/react-query';
import { getData, sendEventToGTM } from 'utils';
import { isImageFormat } from 'design-system';

export const useRfq = () => {
  const { id } = useParams();
  const { token } = useAuthToken();
  const { updateBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();
  const quotationRef = useRef(null);
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);

  useEffect(() => {
    updateBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get Rfq Details
  const {
    data: rfqDetails,
    isError: isRfqDetailsError,
    refetch: refetchRfqDetails,
  } = useQuery({
    queryKey: ['rfqDetails', token, id],
    queryFn: getData(() => getRfqDetails(token!, id!)),
    enabled: !!(token && id),
  });

  if (isRfqDetailsError) {
    navigate('/error');
  }

  // Side effect for getRfqDetails query
  useEffect(() => {
    if (rfqDetails && rfqDetails?.shortID) {
      sendEventToGTM('view_rfq', { rfq_short_id: rfqDetails?.shortID });
      updateBreadcrumbs([
        { label: 'RFQ List', link: '/rfq' },
        {
          label: `RFQ #${rfqDetails?.shortID}`,
          link: `/rfq/details/${id}`,
        },
      ]);
    }
  }, [id, rfqDetails, updateBreadcrumbs]);

  // Get Rfq Files
  const { data: rfqFiles } = useQuery<RFQFile[]>({
    queryKey: ['rfqFiles', token, id],
    queryFn: getData(() => getRfqFiles(token!, id!)),
    enabled: !!(token && id),
    select: res => res.sort(compareExtensions),
  });

  // Get Rfq Quotations
  const { data: rfqQuotations } = useQuery({
    queryKey: ['rfqQuotations', token, id],
    queryFn: getData(() => getBuyerRfqQuotations(token!, id!)),
    enabled: !!(token && id),
  });

  const { cancelRfqMutation, isCancelRfqPending } = useCancelRfq({
    id,
    token,
    refetch: refetchRfqDetails,
    setCancelModalOpen,
  });

  const handleCancelClick = () => {
    if (token && id) {
      cancelRfqMutation();
    }
  };

  const compareExtensions = (a, b) => {
    const extA = a.fileName.split('.').pop().toLowerCase();
    const extB = b.fileName.split('.').pop().toLowerCase();
    const isImageA = isImageFormat(extA);
    const isImageB = isImageFormat(extB);
    if (isImageA && !isImageB) return -1;
    if (!isImageA && isImageB) return 1;
    if (extA < extB) return -1;
    if (extA > extB) return 1;
    if (a.fileName < b.fileName) return -1;
    if (a.fileName > b.fileName) return 1;
    return 0;
  };

  return {
    id,
    rfqDetails,
    rfqFiles,
    rfqQuotations,
    isCancelRfqPending,
    quotationRef,
    handleCancelClick,
    cancelModalOpen,
    setCancelModalOpen,
  };
};
