import { ProductVendorCardResponsive } from './components/product-vendor-card-responsive';
import { ReactComponent as ShareIcon } from 'design-system/public/icons/share.svg';
import { ProductVendorCardFloat } from './components/product-vendor-card-float';
import { VendorModelDto } from 'otl-codegen/dist/marketplace-frontend';
import { ProductDetailsTab } from './components/product-details-tab';
import { IconButton, Loader, Typography } from 'design-system';
import { MediaHtmlParser, TabsComponent } from 'components';
import { ProductMedia } from './components/product-media';
import { MainDetails } from './components/main-details';
import { useProductDetails } from './useProductDetails';
import { Box, Grid, Hidden, Link } from '@mui/material';
import { CSSTransition } from 'react-transition-group';
import { Navigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { Helmet } from 'react-helmet';
import './styles.scss';
import _ from 'lodash';

export const ProductDetails = () => {
  const {
    productId,
    currentTab,
    setCurrentTab,
    productDetails,
    vendorDetails,
    isUpperPartInView,
    isProductDetailsLoading,
    isVendorDetailsLoading,
    navigate,
    handleShareClick,
    productAttributeChange,
    setProductAttributeChange,
    upperPartRef,
    nodeRef,
    isErrorProductDetails,
    isErrorVendorDetails,
  } = useProductDetails();

  const tabs = [
    {
      label: 'Product Details',
      element: <ProductDetailsTab productDetails={productDetails?.productDetailsModel} />,
    },
    {
      label: 'Product Description',
      element: (
        <>
          {productDetails?.productDetailsModel?.fullDescription && (
            <Box className="pt-12 max-sm:pt-6">
              <Typography variant="body1" className="leading-[30px]">
                <MediaHtmlParser
                  className={styles.product__description__tab}
                  htmlString={productDetails?.productDetailsModel?.fullDescription}
                />
              </Typography>
            </Box>
          )}
        </>
      ),
    },
  ];

  if (isProductDetailsLoading || isVendorDetailsLoading) return <Loader />;

  if (!productDetails || isErrorProductDetails || isErrorVendorDetails) return <Navigate to="/error" />;

  return (
    <>
      <Helmet>
        <title>{productDetails?.productDetailsModel.metaTitle || productDetails?.productDetailsModel.name}</title>
        <meta
          name="description"
          content={
            productDetails?.productDetailsModel.metaDescription
              ? productDetails?.productDetailsModel.metaDescription
              : ''
          }
        />
        <meta
          name="keywords"
          content={
            productDetails?.productDetailsModel.metaKeywords ? productDetails?.productDetailsModel.metaKeywords : ''
          }
        />
      </Helmet>
      <div ref={upperPartRef}>
        <Grid container spacing={{ xs: '20px', md: 3 }} marginBottom="24px">
          <Hidden mdUp>
            <Grid item xs={12} className={styles.top__section}>
              {vendorDetails && vendorDetails?.name && (
                <Box
                  className={styles.vendor__container}
                  onClick={() => navigate(`/vendor-details/${vendorDetails?.id}`)}
                >
                  <Link href="#" underline="hover" className="flex items-center">
                    <Box className={styles.vendor__icon__container}>
                      <img
                        src={vendorDetails?.pictureModel.fullSizeImageUrl || ''}
                        alt={vendorDetails?.pictureModel.alternateText || ''}
                      />
                    </Box>
                    <Typography variant="subtitle1" className="text-magentaPink">
                      {vendorDetails?.name}
                    </Typography>
                  </Link>
                </Box>
              )}

              <Box className="flex justify-between mb-1">
                <Typography
                  variant="h5"
                  component="h5"
                  className="text-darkGray me-4 max-w-[80%] flex items-center"
                  id="ProductName"
                >
                  {productDetails?.productDetailsModel.name}
                </Typography>

                <div>
                  <IconButton
                    icon={<ShareIcon />}
                    size="small"
                    className="p-0"
                    onClick={handleShareClick}
                    aria-describedby={productId}
                  />
                </div>
              </Box>

              {productDetails?.productDetailsModel.shortDescription && (
                <Typography variant="body2" className="text-midDarkGray mb-3" id="ShortDescription">
                  {productDetails?.productDetailsModel.shortDescription}
                </Typography>
              )}
            </Grid>
          </Hidden>

          <Grid item xs={12} md={6}>
            <ProductMedia
              pictureModels={productDetails?.productDetailsModel.pictureModels}
              defaultPictureModel={productDetails?.productDetailsModel.defaultPictureModel!}
              productAttributeChange={productAttributeChange}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <MainDetails
              productDetails={productDetails?.productDetailsModel}
              vendorDetails={(vendorDetails as VendorModelDto) || undefined}
              onProductAttributeChange={productAttributeChangeResponse =>
                setProductAttributeChange(productAttributeChangeResponse)
              }
            />
          </Grid>
        </Grid>
      </div>

      <Box className="mt-7 max-sm:mt-5 relative">
        <Grid container spacing={{ xs: 1.5, md: 3 }}>
          <Grid item xs={12} md={7}>
            <TabsComponent value={currentTab} handleChangeTab={setCurrentTab} tabs={tabs} />
          </Grid>

          <Hidden mdDown>
            <Grid item md={1} />
            <Grid item md={4}>
              <CSSTransition
                in={!isUpperPartInView}
                nodeRef={nodeRef}
                timeout={300}
                classNames="float__card__container"
                unmountOnExit
              >
                <div className={styles.card__container} ref={nodeRef}>
                  {!_.isEmpty(vendorDetails) && (
                    <ProductVendorCardFloat
                      vendorDetails={vendorDetails as VendorModelDto}
                      productDetails={productDetails?.productDetailsModel}
                    />
                  )}
                </div>
              </CSSTransition>
            </Grid>
          </Hidden>
        </Grid>
      </Box>

      {!_.isEmpty(vendorDetails) && (
        <Hidden mdUp>
          <ProductVendorCardResponsive
            vendorDetails={vendorDetails as VendorModelDto}
            productDetails={productDetails?.productDetailsModel}
          />
        </Hidden>
      )}
    </>
  );
};
