import { ReactComponent as ArrowRight } from 'design-system/public/icons/arrow-right.svg';
import { getEvents } from 'services/orchestrator.service';
import { AppContainer, Typography } from 'design-system';
import { EventData } from 'services/dtos/events';
import { Box, Hidden } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const EventHeader = () => {
  const [events, setEvents] = useState<EventData[]>([]);

  useEffect(() => {
    getEvents().then(({ data }) => {
      setEvents(data);
    });
  }, []);

  return (
    <>
      {events.map(event => (
        <Box
          sx={{
            height: '40px',
            backgroundColor: 'var(--deepYellow)',
            width: '100%',
            color: 'var(--white)',
          }}
        >
          <Link to={event.values.url}>
            <AppContainer>
              <Box className="flex justify-between h-10 items-center">
                <Box className="flex items-center">
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: {
                        xs: '12px', // small screens
                        sm: '14px', // medium screens and up
                      },
                    }}
                    className="leading-5"
                  >
                    {event.values.content.slice(0, 52)}
                  </Typography>
                </Box>
                <Box className="flex items-center">
                  <Hidden mdDown>
                    <Typography variant="subtitle1" sx={{ fontSize: '12px' }} className="leading-5">
                      Know More
                    </Typography>
                  </Hidden>
                  <ArrowRight className="ms-1" fill="var(--white)" />
                </Box>
              </Box>
            </AppContainer>
          </Link>
        </Box>
      ))}
    </>
  );
};
