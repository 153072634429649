import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MessageCenterProvider } from 'contexts/message-center-context';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BreadcrumbsProvider } from 'contexts/breadcrumbContext';
import { LoadingProvider } from 'contexts/loadingContext';
import { ToastWrapper, baseTheme } from 'design-system';
import { Auth0Provider } from '@auth0/auth0-react';
import { PostHogProvider } from 'posthog-js/react';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@mui/material';
import 'design-system/public/tailwind.css';
import ReactDOM from 'react-dom/client';
import { Helmet } from 'react-helmet';
import { ChatBot } from 'components';
import * as React from 'react';
import { App } from './App';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <LoadingProvider>
        <ThemeProvider theme={baseTheme}>
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN!}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
            cacheLocation={'localstorage'}
            authorizationParams={{
              redirect_uri: `${window.location.origin}/callback`,
              audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN!}/api/v2/`,
            }}
          >
            <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
              <ChatBot />
              <MessageCenterProvider>
                <BreadcrumbsProvider>
                  <ToastWrapper>
                    <Helmet>
                      <title>otrovato, B2B marketplace in Africa</title>
                      <meta
                        name="description"
                        content="otrovato is designed to help buyers and sellers connect while simplifying the process of trading to make it accessible, efficient and secure all over Africa."
                      />
                      <meta
                        name="keywords"
                        content="B2B marketplace, Trade in Africa, Trade in Kenya, African Manufacturers and Suppliers, African Exporters and African Importers, African Trading Platform, Import products from Africa, Top B2B portals, best  African Suppliers, best B2B suppliers, b2b trade portal, top 10 b2b seller, food b2b marketplace, best b2b portals, furniture b2b marketplace, construction b2b marketplace, b2b food suppliers, b2b exporters, chemicals b2b marketplace"
                      />
                    </Helmet>
                    <App />
                    <ToastContainer />
                  </ToastWrapper>
                </BreadcrumbsProvider>
              </MessageCenterProvider>
            </PostHogProvider>
          </Auth0Provider>
        </ThemeProvider>
      </LoadingProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

reportWebVitals();
