import { ReactComponent as SellerIcon } from 'design-system/public/icons/become-a-seller-menu.svg';
import { ReactComponent as CompanyLogo } from 'design-system/public/logo/Logo.svg';
import { ReactComponent as CartIcon } from 'design-system/public/icons/cart.svg';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { RadioCard } from './components/radio-card';
import { Button, Typography } from 'design-system';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { useCustomAuth } from 'hooks';
import { useState } from 'react';

export const SignUp = () => {
  const { loginWithRedirect } = useCustomAuth();
  const [selectedCard, setSelectedCard] = useState(1);

  const isDownLg = useMediaQuery(useTheme().breakpoints.down('lg'));
  const backstoreURL = process.env.REACT_APP_BACKSTORE_BASE_URL;

  document.querySelector('#hubspot-messages-iframe-container')?.classList.add('translate-x-full');
  document.querySelector('html')?.classList.add('remove__gutter');

  const handleCardClick = (cardNumber: number) => {
    setSelectedCard(cardNumber);
  };

  const handleNextClick = () => {
    switch (selectedCard) {
      case 1:
        loginWithRedirect({ authOptions: { authorizationParams: { screen_hint: 'signup' } } });
        break;

      case 2:
        window.location.href = `https://${backstoreURL}/redirecting-signup`;
        break;

      default:
        break;
    }
  };

  return (
    <Box className={styles.gateway__bg}>
      <Box className={styles.overlay__bg}>
        <Box className={styles.content__container}>
          <Box className={styles.introduction__container}>
            <Link to="/" className={styles.company__logo}>
              <CompanyLogo />
            </Link>

            {/* <Typography variant="h3" component="h3" className="text-white uppercase mb-2 max-sm:mb-4">
              make your choice
            </Typography> */}

            <Typography variant="subtitle1" className="text-lightGray">
              Please select your preferred option to proceed.
            </Typography>
          </Box>

          <Grid container spacing={isDownLg ? 2.5 : 6} className={styles.grid__container}>
            <Grid item md={12} lg={6} className="w-full">
              <RadioCard
                cardId={1}
                handleCardClick={handleCardClick}
                selectedCard={selectedCard}
                icon={<CartIcon className={styles.card__icon} />}
                title="I Want to Buy"
                subtitle="Explore a diverse selection of products from trusted suppliers around the region."
              />
            </Grid>

            <Grid item md={12} lg={6} className="w-full">
              <RadioCard
                cardId={2}
                handleCardClick={handleCardClick}
                selectedCard={selectedCard}
                icon={<SellerIcon className={styles.card__icon} />}
                title="I Want to Sell"
                subtitle="Increase your product visibility and grow your business beyond your local market."
              />
            </Grid>
          </Grid>

          <Button size="large" onClick={() => handleNextClick()} className={styles.next__button} label="Next" />
        </Box>
      </Box>
    </Box>
  );
};
