import { BlogsSection } from './components/blogs-section';
import { BlogHero } from './components/blog-hero';
import styles from './styles.module.scss';
import { HtmlParser } from 'components';
import { Loader } from 'design-system';
import { Helmet } from 'react-helmet';
import { useBlog } from './useBlog';

export const Blog = () => {
  const { blog } = useBlog();

  if (!blog) return <Loader />;
  return (
    <>
      <Helmet>
        <title>Insights & Updates about the industry</title>
        <meta
          name="description"
          content="Stay informed with our latest blog posts on industry trends and company news."
        />
        <meta
          name="keywords"
          content="African Continental Free Trade, AfCFTA, free trade, African Union Commission, Africa’s economic strategies, best African companies, africa market place, african sellers , best price in Africa, shipping steps from Africa, african b2b marketplace, b2b portals, online b2b marketplace, b2b suppliers, b2b trading"
        />
      </Helmet>
      {blog && blog.values && (
        <>
          {blog?.values.image && <BlogHero blog={blog} />}
          <HtmlParser htmlString={blog?.values.content} className={styles.blog__html} />

          <BlogsSection id="you-may-also-read" title="You May Also Read" slice={7} />
        </>
      )}
    </>
  );
};
