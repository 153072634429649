import {
  Box,
  Pagination as MuiPagination,
  PaginationProps as MuiPaginationProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';

export interface PaginationProps extends MuiPaginationProps {
  handleChangePage: (val: number) => void;
}

export const Pagination = ({ handleChangePage, ...rest }: PaginationProps) => {
  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  return (
    <Box className="flex justify-end">
      <MuiPagination
        size={isDownMd ? 'medium' : 'large'}
        variant="outlined"
        color="magentaPink"
        onChange={(e, value) => handleChangePage(value)}
        {...rest}
      />
    </Box>
  );
};
