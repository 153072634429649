import { RadioButton, Typography } from 'design-system';
import styles from './styles.module.scss';
import { Box, Card } from '@mui/material';

export const RadioCard = ({ cardId, handleCardClick, selectedCard, icon, title, subtitle }) => {
  return (
    <>
      <Card onClick={() => handleCardClick(cardId)} className={styles.card}>
        <Box className={styles.card__header__container}>
          <Box className="flex items-center">
            <Box className="me-3">{icon}</Box>
            <Typography variant="h6" component="h6" className="text-darkGray text-start">
              {title}
            </Typography>
          </Box>
          <RadioButton color="magentaPink" checked={selectedCard === cardId} />
        </Box>

        <Typography variant="body2" className={styles.grid__item__text}>
          {subtitle}
        </Typography>
      </Card>
    </>
  );
};
