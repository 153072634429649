import { RefinementListProps, useRefinementList } from 'react-instantsearch';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { AlgoliaCheckBoxFilter } from '../algolia-checkbox-filter';
import { Box, Divider } from '@mui/material';
import { SearchField } from 'design-system';
import { useState, useEffect } from 'react';
import { Typography } from 'design-system';

export const AlgoliaRefinementList = (props: RefinementListProps) => {
  const [algoliaTextFieldValue, setAlgoliaTextFieldValue] = useState<string>('');
  const [isListExpanded, setIsListExpanded] = useState<boolean>(true);

  const { isFromSearch, items, canToggleShowMore, toggleShowMore, isShowingMore, refine, searchForItems } =
    useRefinementList(props);

  useEffect(() => {
    if (algoliaTextFieldValue?.length >= 3 || algoliaTextFieldValue?.length === 0)
      searchForItems(algoliaTextFieldValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [algoliaTextFieldValue]);

  const handleAlgoliaCheckboxChange = (event: any) => {
    refine(event.target.value);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // You can change this to 'auto' if you want instant scrolling
      });
    }, 150);
  };

  const handleAlgoliaTextFieldChange = (newValue: string) => {
    setAlgoliaTextFieldValue(newValue);
  };

  return (
    <>
      {isListExpanded && <Divider className="mb-8 mt-4 max-sm:mb-4 border-antiFlashWhite" />}

      <Box className="flex justify-between mb-5 max-sm:mb-4">
        <Typography variant="subtitle1" className="text-darkGray capitalize">
          {props.attribute}
        </Typography>

        <button onClick={() => setIsListExpanded(!isListExpanded)} className="cursor-pointer">
          {isListExpanded && <KeyboardArrowUp style={{ color: 'var(--midLightGray)' }} />}
          {!isListExpanded && <KeyboardArrowDown style={{ color: 'var(--midLightGray)' }} />}
        </button>
      </Box>

      {isListExpanded && (
        <>
          {props.searchable && (
            <Box className="my-4">
              <SearchField
                size="small"
                placeholder={`Search by ${props.attribute}`}
                onInputChange={handleAlgoliaTextFieldChange}
                hasClear
              />
            </Box>
          )}

          {props.searchable && isFromSearch && items.length === 0 && (
            <Typography variant="body2" className="text-gray mb-4">
              No Search Results
            </Typography>
          )}

          <AlgoliaCheckBoxFilter
            isShowingMore={isShowingMore}
            toggleShowMore={toggleShowMore}
            canToggleShowMore={canToggleShowMore}
            items={items}
            onChangeCheckBox={(e: any) => handleAlgoliaCheckboxChange(e)}
          />
        </>
      )}
    </>
  );
};
