import { ReactComponent as CaretRight } from 'design-system/public/icons/caret-right.svg';
import { Swiper, SwiperClass, SwiperProps, SwiperSlide } from 'swiper/react';
import { VendorModelDto } from 'otl-codegen/dist/marketplace-frontend';
import { Loader, Typography, VendorCard } from 'design-system';
import { useTheme, useMediaQuery } from '@mui/material';
import { FreeMode, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { sendEventToGTM } from 'utils';
import { Box } from '@mui/system';
import { useRef } from 'react';

type Props = {
  topVendors: VendorModelDto[];
};

export const TopVendors = ({ topVendors }: Props) => {
  const sliderRef = useRef<SwiperClass>();

  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  const topBrandsSwiperProps: SwiperProps = {
    direction: 'horizontal',
    spaceBetween: isDownMd ? 12 : 24,
    slidesPerView: 'auto',
    modules: [FreeMode, Navigation],
    slideToClickedSlide: true,
    navigation: {
      prevEl: '.vendor-swiper.custom-swiper-button-prev',
      nextEl: '.vendor-swiper.custom-swiper-button-next',
      disabledClass: 'disabled-swiper',
    },
    onSwiper: swiper => {
      sliderRef.current = swiper;
    },
    loop: false,
  };

  if (!topVendors) return <Loader />;

  return (
    <>
      {topVendors.length > 0 && (
        <>
          <Typography variant="h3" component="h3" className="text-darkGray mb-4 max-sm:mb-3">
            Top Brands
          </Typography>

          <div className="swiper-with-arrows">
            <div className="vendor-swiper custom-swiper-button-prev" onClick={() => sliderRef?.current?.slidePrev()}>
              <CaretRight />
            </div>

            <Swiper {...topBrandsSwiperProps}>
              {topVendors.map(vendor => (
                <SwiperSlide style={{ width: 'auto' }} key={`top-vendor-slide-${vendor?.id}`}>
                  <Link
                    to={`/vendor-details/${vendor.id}`}
                    key={`Top-vendor-#${vendor.id}`}
                    onClick={() =>
                      sendEventToGTM('top_brands_click', {
                        vendor_id: vendor.id,
                      })
                    }
                  >
                    <Box
                      sx={{
                        width: { xs: '70px', md: '150px' },
                        height: { xs: '70px', md: '150px' },
                      }}
                    >
                      <VendorCard
                        vendor={{
                          id: vendor.id,
                          pictureModel: {
                            fullSizeImageUrl: vendor.pictureModel.fullSizeImageUrl || '',
                            alternateText: vendor.pictureModel.alternateText || '',
                          },
                        }}
                      />
                    </Box>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>

            <div className="vendor-swiper custom-swiper-button-next" onClick={() => sliderRef?.current?.slideNext()}>
              <CaretRight />
            </div>
          </div>
        </>
      )}
    </>
  );
};
