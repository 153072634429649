import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';

export const TextField = ({ color = 'magentaPink', variant = 'outlined', ...rest }: MuiTextFieldProps) => {
  const handleKeyDown = (event: any) => {
    if (rest.type === 'number' && (event.key === 'e' || event.key === 'E')) {
      event.preventDefault();
    }
  };

  return <MuiTextField {...rest} color={color} variant={variant} autoComplete="off" onKeyDown={handleKeyDown} />;
};
