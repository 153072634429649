import { ReactComponent as CaretRight } from 'design-system/public/icons/caret-right.svg';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Typography } from 'design-system';
import styles from './styles.module.scss';
import { HtmlParser } from 'components';
import { useState } from 'react';

export const CustomAccordion = ({ data }) => {
  const [expanded, setExpanded] = useState<number | false>(0);
  const handleChange = (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      {data.results.map((data, idx) => (
        <Accordion
          key={`data-item-${data.values.title}`}
          elevation={0}
          className="mb-4 border-solid border-[1px] border-lightGray rounded-xl"
          expanded={expanded === idx}
          onChange={handleChange(idx)}
        >
          <AccordionSummary
            expandIcon={<CaretRight className={styles.accordion__icon} />}
            aria-controls="panel1-content"
            id="panel1-header"
            className=" px-2"
          >
            <Typography variant="h5" component="h5" className="text-darkGray">
              {data.values?.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" className="text-midDarkGray">
              <HtmlParser htmlString={data.values?.description} className={styles.data__description} />
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};
