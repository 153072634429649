import { Typography } from 'design-system';
import styles from './styles.module.scss';
import { Helmet } from 'react-helmet';

export const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>User's privacy</title>
        <meta name="description" content="Understand how we collect, use, and protect your personal data." />
        <meta
          name="keywords"
          content=" company liscence template, privacy policy example, personal information protection, data privacy policy, personal information protection, protect your privacy online, Secured Payment Policies, cookies privacy, privacy policy for online store, web privacy policy, web privacy policy"
        />
      </Helmet>
      <div className={styles.privacy}>
        <Typography
          variant="h1"
          component="h1"
          className="text-magentaPink pb-0 text-[32px] leading-[48px] max-md:pt-6 "
        >
          Privacy Policy
        </Typography>

        <Typography variant="body1" className="pb-3 text-xs">
          Last updated 1 November 2023
        </Typography>

        <Typography variant="body1">
          This privacy notice for otrovato by O Trade and Logistics ("we," "us," or "our"), describes how and why we
          might collect, store, use, and/or share your information ("process") when you use our services ("Services").
        </Typography>
        <Typography variant="body1">
          Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with
          our policies and practices, please do not use our Services. If you still have any questions or concerns,
          please contact us at privacy@otl.trade.
        </Typography>
        <Typography variant="h2" component="h2">
          SUMMARY OF KEY POINTS
        </Typography>
        <Typography variant="body1">
          This summary provides key points from our privacy notice, but you can find out more details about any of these
          topics by clicking the link following each key point or by using our table of contents below to find the
          section you are looking for.
        </Typography>
        <Typography variant="h2" component="h2">
          1. WHAT INFORMATION DO WE COLLECT?
        </Typography>
        <Typography variant="h3" component="h3" className="pt-0">
          Personal information you disclose to us.
        </Typography>
        <Typography variant="body1">We collect personal information that you provide to us.</Typography>
        <Typography variant="body1">
          We collect personal information that you voluntarily provide to us when you register on the Services, express
          an interest in obtaining information about us or our products and Services, when you participate in activities
          on the Services, or otherwise when you contact us.
        </Typography>
        <Typography variant="body1">Sensitive Information. We do not process sensitive information.</Typography>
        <Typography variant="h3" component="h3">
          Information automatically collected
        </Typography>
        <Typography variant="body1">
          Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is
          collected automatically when you visit our Services.
        </Typography>
        <Typography variant="body1">
          We automatically collect certain information when you visit, use, or navigate the Services. This information
          does not reveal your specific identity (like your name or contact information) but may include device and
          usage information, such as your IP address, browser and device characteristics, operating system, language
          preferences, referring URLs, device name, country, location, information about how and when you use our
          Services, and other technical information. This information is primarily needed to maintain the security and
          operation of our Services, and for our internal analytics and reporting purposes.
        </Typography>
        <Typography variant="body1">
          Like many businesses, we also collect information through cookies and similar technologies.
        </Typography>
        <Typography variant="h2" component="h2">
          2. HOW DO WE PROCESS YOUR INFORMATION?
        </Typography>
        <Typography variant="body1">
          In Short: We process your information to provide, improve, and administer our Services, communicate with you,
          for security and fraud prevention, and to comply with law. We may also process your information for other
          purposes with your consent.
        </Typography>
        <Typography variant="body1">
          We process your personal information for a variety of reasons, depending on how you interact with our
          Services, including:
        </Typography>
        <Typography variant="h2" component="h2">
          3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </Typography>
        <Typography variant="body1">
          In Short: We may share information in specific situations described in this section and/or with the following
          third parties.
        </Typography>
        <Typography variant="body1">
          We may need to share your personal information in the following situations:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              <strong>Business Transfers.</strong> We may share or transfer your information in connection with, or
              during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion
              of our business to another company.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Affiliates.</strong> We may share your information with our affiliates, in which case we will
              require those affiliates to honor this privacy notice. Affiliates include our parent company and any
              subsidiaries, joint venture partners, or other companies that we control or that are under common control
              with us.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Business Partners.</strong> We may share your information with our business partners to offer you
              certain products, services, or promotions.
            </Typography>
          </li>
        </ul>
        <Typography variant="h2" component="h2">
          4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </Typography>
        <Typography variant="body1">
          In Short: We may use cookies and other tracking technologies to collect and store your information.
        </Typography>
        <Typography variant="body1">
          We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
          information. Specific information about how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice.
        </Typography>
        <Typography variant="h2" component="h2">
          5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
        </Typography>
        <Typography variant="body1">
          In Short: If you choose to register or log in to our Services using a social media account, we may have access
          to certain information about you.
        </Typography>
        <Typography variant="body1">
          Our Services offer you the ability to register and log in using your third-party social media account details
          (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile
          information about you from your social media provider. The profile information we receive may vary depending
          on the social media provider concerned, but will often include your name, email address, friends list, and
          profile picture, as well as other information you choose to make public on such a social media platform.
        </Typography>
        <Typography variant="body1">
          We will use the information we receive only for the purposes that are described in this privacy notice or that
          are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not
          responsible for, other uses of your personal information by your third-party social media provider. We
          recommend that you review their privacy notice to understand how they collect, use, and share your personal
          information, and how you can set your privacy preferences on their sites and apps.
        </Typography>
        <Typography variant="h2" component="h2">
          6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
        </Typography>
        <Typography variant="body1">
          In Short: We may transfer, store, and process your information in countries other than your own.
        </Typography>
        <Typography variant="body1">
          Our servers are located in Europe. If you are accessing our Services from outside, please be aware that your
          information may be transferred to, stored, and processed by us in our facilities and by those third parties
          with whom we may share your personal information, in and other countries.
        </Typography>
        <Typography variant="body1">
          You may be a resident in a country that does not necessarily have data protection laws or other similar laws
          as comprehensive as those in your country. However, we will take all necessary measures to protect your
          personal information in accordance with this privacy notice and applicable law.
        </Typography>
        <Typography variant="h2" component="h2">
          7. HOW LONG DO WE KEEP YOUR INFORMATION?
        </Typography>
        <Typography variant="body1">
          In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy
          notice unless otherwise required by law.
        </Typography>
        <Typography variant="body1">
          We will only keep your personal information for as long as it is necessary for the purposes set out in this
          privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or
          other legal requirements).
        </Typography>
        <Typography variant="body1">
          When we have no ongoing legitimate business need to process your personal information, we will either delete
          or anonymize such information, or, if this is not possible (for example, because your personal information has
          been stored in backup archives), then we will securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </Typography>
        <Typography variant="h2" component="h2">
          8. DO WE COLLECT INFORMATION FROM MINORS?
        </Typography>
        <Typography variant="body1">
          In Short: We do not knowingly collect data from or market to children under 18 years of age.
        </Typography>
        <Typography variant="body1">
          We do not knowingly solicit data from or market to children under 18 years of age or any other age as may be
          required by the relevant law. By using the Services, you represent that you are at least 18 or that you are
          the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn
          that personal information from users less than 18 years of age has been collected, we will deactivate the
          account and take reasonable measures to promptly delete such data from our records. If you become aware of any
          data, we may have collected from children under age 18, please contact us at privacy@otl.trade.
        </Typography>
        <Typography variant="h2" component="h2">
          9. WHAT ARE YOUR PRIVACY RIGHTS?
        </Typography>
        <Typography variant="body1">
          In Short: You may review, change, or terminate your account at any time.
        </Typography>
        <Typography variant="body1">
          Withdrawing your consent: If we are relying on your consent to process your personal information, which may be
          express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at
          any time. You can withdraw your consent at any time by contacting us by using the contact details provided in
          the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
        </Typography>
        <Typography variant="body1">
          However, please note that this will not affect the lawfulness of the processing before its withdrawal nor,
          when applicable law allows, will it affect the processing of your personal information conducted in reliance
          on lawful processing grounds other than consent.
        </Typography>
        <Typography variant="body1">
          <strong>Account Information</strong>
        </Typography>
        <Typography variant="body1">
          If you would at any time like to review or change the information in your account or terminate your account,
          you can:
        </Typography>
        <Typography variant="body1">
          Upon your request to terminate your account, we will deactivate or delete your account and information from
          our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot
          problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal
          requirements.
        </Typography>
        <Typography variant="h2" component="h2">
          10. CONTROLS FOR DO-NOT-TRACK FEATURES
        </Typography>
        <Typography variant="body1">
          Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT")
          feature or setting you can activate to signal your privacy preference not to have data about your online
          browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and
          implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or
          any other mechanism that automatically communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will inform you about that practice in a
          revised version of this privacy notice.
        </Typography>
        <Typography variant="h2" component="h2">
          11. DO WE MAKE UPDATES TO THIS NOTICE?
        </Typography>
        <Typography variant="body1">
          In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.
        </Typography>
        <Typography variant="body1">
          We may update this privacy notice from time to time. The updated version will be indicated by an updated
          "Revised" date and the updated version will be effective as soon as it is accessible. If we make material
          changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by
          directly sending you a notification. We encourage you to review this privacy notice frequently to be informed
          of how we are protecting your information.
        </Typography>
        <Typography variant="h2" component="h2">
          12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </Typography>
        <Typography variant="body1">
          If you have questions or comments about this notice, you may email us at privacy@otl.trade or contact us by
          post at:
        </Typography>
        <Typography variant="body1">
          <strong>OTrade and Logistics</strong>
        </Typography>
        <Typography variant="body1">
          <strong>Nile City Towers, 2005A Cornish El Nile, Ramlet Beaulac, Cairo, Egypt. Cairo, Cairo Egypt</strong>
        </Typography>
        <Typography variant="body1">
          <strong>Phone: (+20)1000086761</strong>
        </Typography>
        <Typography variant="h2" component="h2">
          13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
        </Typography>
        <Typography variant="body1">
          Based on the applicable laws of your country, you may have the right to request access to the personal
          information we collect from you, change that information, or delete it. To request to review, update, or
          delete your personal information, please email us at privacy@otl.trade.
        </Typography>
      </div>
    </>
  );
};
