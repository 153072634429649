import { Box, Hidden, MenuItem, Select, SelectProps, Typography } from '@mui/material';
import { ReactComponent as SortIcon } from 'design-system/public/icons/sort.svg';
import { SelectListItemDto } from 'otl-codegen/dist/marketplace-frontend';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useState } from 'react';

type SortProps = {
  currentValue: number;
  availableSortOptions: SelectListItemDto[];
  setOrderBy: (value: number) => void;
} & SelectProps;

// Todo: Use the design system sort
export const NopSort = ({ currentValue, availableSortOptions, setOrderBy, ...props }: SortProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const foundOption = availableSortOptions.find(element => element.value === currentValue.toString() || '');

  const handleExpandClick = () => {
    const disabled = props.disabled;
    !disabled && setOpen(!open);
  };

  return (
    <Select
      open={open}
      sx={{ width: 'auto', fontSize: '13px', height: '40px' }}
      defaultValue={props.defaultValue}
      variant="standard"
      disableUnderline
      renderValue={(value: any) => {
        setOrderBy(+value);
        return (
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <SortIcon />
            <Hidden mdDown>
              <Typography className="text-midLightGray" variant="body1">
                Sort by:
              </Typography>{' '}
              <Typography className="text-darkGray" variant="body1">
                {foundOption && foundOption?.text}
              </Typography>
            </Hidden>

            <Box className="cursor-pointer text-midLightGray" onClick={() => handleExpandClick()}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </Box>
          </Box>
        );
      }}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      IconComponent={() => <></>}
      MenuProps={{
        sx: {
          '&& .Mui-selected': {
            backgroundColor: 'var(--secondaryLight)',
          },
        },
      }}
    >
      {availableSortOptions &&
        availableSortOptions.map(
          option =>
            option.value && (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ),
        )}
    </Select>
  );
};
