import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { AppContainer, Typography } from 'design-system';
import { aboutOtrovatoData } from './data';
import styles from './styles.module.scss';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';

export const OldAboutOtrovato = () => {
  const { updateBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    updateBreadcrumbs([{ label: 'About otrovato', link: '/about-otrovato' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Who we are</title>
        <meta name="description" content="Know about our company, who we are and who we operate!" />
        <meta
          name="keywords"
          content="best african products, easiest way to buy from africa, Trade Solutions, Online Trading Marketplace, Import construction material, Import food and beverage,  African  Sellers, Trusted African Suppliers Network, Easiest way to import from Africa, Importing steps, expand my business, ways to expand my company, African B2B Marketplace Registration"
        />
      </Helmet>
      <AppContainer>
        <div className={`${styles.about__img} max-sm:h-[26vh] max-sm:min-h-[160px]`}>
          <div className={styles.about__layout} />
          <div className={styles.about__img__header}>
            <Typography variant="h1" component="h1">
              Now, Trading is at your fingertips
            </Typography>
          </div>
        </div>
        <div className={`${styles.about__info} max-sm:py-6 `}>
          <Typography variant="h3" component="h3" className="text-darkGray">
            Trading at Your Fingertips
          </Typography>
          {aboutOtrovatoData &&
            aboutOtrovatoData.map(data => (
              <Typography variant="body1" className="text-midDarkGray pt-6 pb-0 leading-8 max-sm:pt-3">
                {data.body}
              </Typography>
            ))}
        </div>
      </AppContainer>
    </>
  );
};
