import { ErrorFeedback } from 'src/components/typographies/error-feedback';
import { Controller, Control } from 'react-hook-form';
import { FormControlLabel, Box } from '@mui/material';
import { Checkbox } from '../../core/checkbox';

export interface ControlledCheckboxProps {
  name: string;
  control: Control<any>;
  label: string;
}

export const ControlledCheckbox = ({ name, label, control }: ControlledCheckboxProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={false}
      render={({ field, fieldState }) => (
        <Box className="flex flex-col">
          <FormControlLabel
            control={<Checkbox {...field} color={fieldState.error ? 'red' : 'magentaPink'} />}
            label={label}
            className="me-1"
          />
          {fieldState.error && <ErrorFeedback message={fieldState.error.message} />}
        </Box>
      )}
    />
  );
};
