import { Typography, Slider } from 'design-system';
import { Box } from '@mui/material';

export const CustomSlider = ({ ...props }: any) => {
  return (
    <>
      <Box sx={{ px: 1 }}>
        <Slider valueLabelDisplay="off" color="magentaPink" {...props} />
      </Box>
      <Box className="flex">
        <Typography variant="subtitle1" component="span" className="w-full flex justify-start">
          From:&nbsp;
          <span className="text-magentaPink">$ {props.value && props.value[0]}</span>
        </Typography>
        <Typography variant="subtitle1" component="span" className="w-full flex justify-end">
          To:&nbsp;
          <span className="text-magentaPink">$ {props.value && props.value[1]}</span>
        </Typography>
      </Box>
    </>
  );
};
