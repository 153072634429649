import { ReactComponent as DownloadIcon } from 'design-system/public/icons/Download.svg';
import { ReactComponent as HistoryIcon } from 'design-system/public/icons/history.svg';
import { QuotationStatusEnum, RfqStatusEnum } from 'services/types/generic/enums';
import { QuotationObj } from 'services/types/quotation/quotationObj.type';
import { Typography, Button, Loader } from 'design-system';
import PdfIcon from 'design-system/public/images/pdf.png';
import { RFQ } from 'services/types/generic/rfq-entity';
import { HistoryModal } from '../modals/history-modal';
import { QuotationStatus } from '../quotation-status';
import { Box, Card, Link } from '@mui/material';
import styles from './styles.module.scss';
import { useState } from 'react';
import moment from 'moment';

type Props = {
  rfqDetails: RFQ;
  vendorQuotations: QuotationObj[];
  onAccept: Function;
  onNegotiate: Function;
  onReject: Function;
  showLoading: boolean;
};

export const VendorQuotationCard = ({
  vendorQuotations,
  onAccept,
  onNegotiate,
  onReject,
  rfqDetails,
  showLoading,
}: Props) => {
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const buttonsDisabled =
    rfqDetails.status === RfqStatusEnum.Closed ||
    vendorQuotations[0].status === QuotationStatusEnum.Expired ||
    vendorQuotations[0].vendorName === 'Inactive Vendor';

  return (
    <>
      <HistoryModal open={historyModalOpen} setOpen={setHistoryModalOpen} history={vendorQuotations.slice(1)} />

      <Card elevation={0} className={styles.vendor__quotation__card}>
        {!showLoading && (
          <>
            <Box id="#header" className="flex items-center justify-between h-10 mb-2">
              <Box className="flex items-center max-w-[70%]">
                <Typography variant="h6" component="h6" className={styles.vendor__name}>
                  {vendorQuotations[0].vendorName}
                </Typography>

                {vendorQuotations[0].status !== QuotationStatusEnum.New && (
                  <QuotationStatus status={vendorQuotations[0].status} />
                )}
              </Box>

              {vendorQuotations.length > 1 && (
                <Box className="flex items-center cursor-pointer" onClick={() => setHistoryModalOpen(true)}>
                  <HistoryIcon />
                  <Typography variant="body2" className="ms-1 text-midLightGray">
                    History
                  </Typography>
                </Box>
              )}
            </Box>

            <Box className="flex items-center mb-3">
              <Typography variant="body2" className="text-midLightGray">
                {vendorQuotations[0]?.createdAt && moment(vendorQuotations[0]?.createdAt).utc().format('MMMM DD, YYYY')}{' '}
                {' - '}
                {vendorQuotations[0]?.expDate && moment(vendorQuotations[0]?.expDate).utc().format('MMMM DD, YYYY')}
              </Typography>
            </Box>

            <Box className="flex justify-between items-center mb-2">
              <Link href={vendorQuotations[0].url}>
                <Button
                  variant="outlined"
                  color="neutral"
                  className="border-lightGray text-green"
                  startIcon={<img src={PdfIcon} alt="" className="w-5" />}
                  endIcon={<DownloadIcon fill="var(--green)" className="w-5" />}
                  label="Download"
                />
              </Link>
            </Box>

            {rfqDetails?.productName && rfqDetails?.quantity && (
              <Box>
                <Typography variant="subtitle1" className="text-darkGray">
                  {rfqDetails?.productName},{' '}
                  <Typography variant="body1" className="text-midLightGray" component="span">
                    {rfqDetails?.quantity}/{rfqDetails?.unit}
                  </Typography>
                </Typography>
              </Box>
            )}

            {(vendorQuotations[0].status === QuotationStatusEnum.New ||
              vendorQuotations[0].status === QuotationStatusEnum.Expired) && (
              <Box id="#actions-new" className="flex items-center mt-4">
                <Button
                  size="small"
                  className="me-3 w-[110px]"
                  onClick={() => onAccept(vendorQuotations[0])}
                  disabled={buttonsDisabled}
                  label="Accept"
                />

                <Button
                  size="small"
                  variant="outlined"
                  className="me-4  w-[110px]"
                  onClick={() => onNegotiate(vendorQuotations[0])}
                  disabled={buttonsDisabled}
                  label="Negotiate"
                />

                <Box className="flex me-3">
                  <Typography variant="body2" className="text-midDarkGray">
                    or
                  </Typography>

                  {/* Todo: Change to Design system button */}
                  <button
                    className="ms-2 text-midDarkGray disabled:text-gray"
                    onClick={() => onReject(vendorQuotations[0])}
                    disabled={buttonsDisabled}
                  >
                    <Typography variant="body2">Reject</Typography>
                  </button>
                </Box>
              </Box>
            )}

            {vendorQuotations[0].status === QuotationStatusEnum.Won && (
              <Box id="#actions-any" className="flex items-center mt-4">
                <Button
                  size="small"
                  variant="outlined"
                  className="me-6"
                  onClick={() => onNegotiate(vendorQuotations[0])}
                  label="Negotiate"
                />
              </Box>
            )}
          </>
        )}

        {showLoading && <Loader />}
      </Card>
    </>
  );
};
