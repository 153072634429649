import { SelectListItemDto } from 'otl-codegen/dist/marketplace-frontend';
import { FormGroup, FormControlLabel } from '@mui/material';
import { Checkbox, Typography } from 'design-system';

type CheckboxFilterProps = {
  manufacturerOptions: SelectListItemDto[] | null | undefined;
  onChangeManufacturer: any;
  manufacturerIds?: number[];
};

export const CheckboxFilter = ({ manufacturerOptions, onChangeManufacturer, manufacturerIds }: CheckboxFilterProps) => {
  return (
    <FormGroup className="mt-4">
      <Typography variant="subtitle1" className="mb-3 text-darkGray">
        Product Manufacturer
      </Typography>

      {manufacturerOptions?.map((manufacturer, idx) => {
        const manufacturerValue = Number(manufacturer.value);
        return (
          <FormControlLabel
            key={`manufacturer ${idx}`}
            control={
              <Checkbox
                color="magentaPink"
                onChange={(e, v) => onChangeManufacturer(e, v)}
                value={manufacturerValue || ''}
                checked={manufacturerIds && manufacturerIds.includes(manufacturerValue)}
              />
            }
            label={manufacturer.text}
          />
        );
      })}
    </FormGroup>
  );
};
