import { ReactComponent as RFQIcon } from 'design-system/public/icons/rfq.svg';
import { Button, RfqExpired, Typography } from 'design-system';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { useEffect } from 'react';

export const ExpiredRfq = () => {
  const { updateBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    updateBreadcrumbs([{ label: 'RFQ Expired', link: '/rfq/email-expired' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="p-12 max-w-[1120px] m-auto max-sm:bg-transparent max-sm:px-0 max-sm:pt-0">
      <Box className="flex flex-col justify-center items-center">
        <Box className={styles.lottie__container}>
          <RfqExpired />
        </Box>
        <Typography variant="h4" component="h4" className="text-darkGray mb-2">
          Sorry! your request has expired
        </Typography>
        <Typography variant="body1" className="text-midLightGray mb-4">
          create a new one now
        </Typography>
        <Link to="/rfq/submit/first-step">
          <Button label="Request for Quotation" startIcon={<RFQIcon stroke="var(--white)" width={24} height={24} />} />
        </Link>
      </Box>
    </Box>
  );
};
