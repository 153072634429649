import { ReactComponent as BecomeASellerIcon } from 'design-system/public/icons/become-a-seller-menu.svg';
import { ReactComponent as UserLoggedOutIcon } from 'design-system/public/icons/user_logged_out.svg';
import { ReactComponent as UserLoggedInIcon } from 'design-system/public/icons/user_logged_in.svg';
import { ReactComponent as VerifiedIcon } from 'design-system/public/icons/verified-profile.svg';
import { ReactComponent as UserProfileIcon } from 'design-system/public/icons/user-profile.svg';
import { ReactComponent as ArrowRightIcon } from 'design-system/public/icons/caret-right.svg';
import { ReactComponent as CaretRight } from 'design-system/public/icons/caret-right.svg';
import { ReactComponent as LogoutIcon } from 'design-system/public/icons/logout.svg';
import { ReactComponent as CloseIcon } from 'design-system/public/icons/close.svg';
import { ReactComponent as CompanyLogo } from 'design-system/public/logo/Logo.svg';
import { MenuItem, ListItemIcon, Menu, Box, Divider, Drawer } from '@mui/material';
import { ReactComponent as RFQIcon } from 'design-system/public/icons/rfq.svg';
import { Typography, IconButton, Button } from 'design-system';
import { useAccountMenu } from './useAccountMenu';
import styles from './styles.module.scss';
import { LogoutModal } from 'components';
import { Link } from 'react-router-dom';
import { userHasMetadata } from 'utils';
import { HTMLAttributes } from 'react';
import classNames from 'classnames';
import './styles.scss';

const LoggedOutDrawer = ({ loggedOutDrawer, setLoggedOutDrawer }) => {
  const { onLogin } = useAccountMenu();

  return (
    <Drawer anchor={'bottom'} open={loggedOutDrawer} onClose={() => setLoggedOutDrawer(false)}>
      <Box className="p-5">
        <Box className="flex items-center justify-between mb-3">
          <Box className="flex items-center">
            <CompanyLogo className="me-3" />
            <Typography variant="h1" component="h1" className="text-darkGray">
              Hello,
            </Typography>
          </Box>

          <CloseIcon className="w-6 h-6 fill-midLightGray" onClick={() => setLoggedOutDrawer(false)} />
        </Box>

        <Typography variant="body2" className="text-midLightGray mb-3">
          Join our platform to show more ...
        </Typography>

        <Box className="flex flex-col w-full items-center">
          <Button size="small" className="w-5/6 mb-3" onClick={() => onLogin()} label="Login" />

          <Link to="/signup" className="w-5/6 mb-3" onClick={() => setLoggedOutDrawer(false)}>
            <Button size="small" variant="outlined" className="w-full" label="Sign Up" />
          </Link>
        </Box>
      </Box>
    </Drawer>
  );
};

const LoggedInDrawer = ({ loggedInDrawer, setLoggedInDrawer, menuItems }) => {
  const { getUserName } = useAccountMenu();

  return (
    <Drawer anchor="bottom" open={loggedInDrawer} onClose={() => setLoggedInDrawer(false)}>
      <Box className="flex items-center justify-between h-16 bg-secondaryLight p-5">
        <Typography variant="h3" component="h3" className="text-darkGray">
          Welcome, {getUserName()}
        </Typography>

        <CloseIcon className="w-6 h-6 fill-midLightGray" onClick={() => setLoggedInDrawer(false)} />
      </Box>

      <Box className="p-5">
        {menuItems.map((menuItem: any) => (
          <Box key={`menuItem-${menuItem.text}`} className={styles.menu__item} onClick={menuItem.onClick}>
            <div className="flex">
              <Box className="me-1">{menuItem.icon}</Box>
              <Typography variant="subtitle2" className="text-darkGray">
                {menuItem.text}
              </Typography>
            </div>

            <ArrowRightIcon width="24px" height="24px" />
          </Box>
        ))}
      </Box>
    </Drawer>
  );
};

export const AccountMenu = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  const {
    isAuthenticated,
    myUser,
    navigate,
    anchorEl,
    open,
    isDownMd,
    loggedOutDrawer,
    setLoggedOutDrawer,
    loggedInDrawer,
    setLoggedInDrawer,
    logoutOpen,
    setLogoutOpen,
    handleClickMenu,
    handleCloseMenu,
    handleLogoutModalPrimaryClick,
    pathname,
    onLogin,
    onSignup,
    getUserName,
  } = useAccountMenu();

  if (pathname !== '/profile/verified') document.querySelector('#verified-icon')?.classList.remove('show');

  const responsiveMenuItems = [
    {
      onClick: () => {
        navigate('/profile');
        setLoggedInDrawer(false);
      },
      icon: <UserProfileIcon className={styles.menu__item__icon} />,
      text: 'Profile',
    },
    {
      onClick: () => {
        navigate('/rfq');
        setLoggedInDrawer(false);
      },
      icon: <RFQIcon stroke="var(--darkGray)" className={styles.menu__item__icon} />,
      text: 'Manage RFQ',
    },
    {
      onClick: () => {
        window.location.href = `https://${process.env.REACT_APP_BACKSTORE_BASE_URL}/redirecting-signup`;
        setLoggedInDrawer(false);
      },
      icon: <BecomeASellerIcon />,
      text: 'Become a Seller',
    },
    {
      onClick: () => setLogoutOpen(true),
      icon: <LogoutIcon className={styles.menu__item__icon} />,
      text: 'Logout',
    },
  ];

  const menuItems = [
    {
      onClick: () => {
        navigate('/profile');
        setLoggedInDrawer(false);
      },
      icon: <UserProfileIcon className={styles.menu__item__icon} />,
      text: 'Profile',
    },
    {
      onClick: () => {
        navigate('/rfq');
        setLoggedInDrawer(false);
      },
      icon: <RFQIcon stroke="var(--darkGray)" className={styles.menu__item__icon} />,
      text: 'Manage RFQ',
    },
    {
      onClick: () => setLogoutOpen(true),
      icon: <LogoutIcon className={styles.menu__item__icon} />,
      text: 'Logout',
    },
  ];

  return (
    <>
      <LogoutModal
        logoutOpen={logoutOpen}
        setLogoutOpen={setLogoutOpen}
        handleLogoutModalPrimaryClick={handleLogoutModalPrimaryClick}
      />

      <Box className={className} {...props}>
        {isAuthenticated && !isDownMd && (
          <IconButton
            size="small"
            color="magentaPink"
            onClick={handleClickMenu}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            border={false}
          >
            <Box className="relative me-2">
              <UserLoggedInIcon />
              <Box className="verified__box" id="verified-icon">
                <VerifiedIcon />
              </Box>
              {userHasMetadata(myUser) && pathname !== '/profile/verified' && (
                <Box className="verified__icon">
                  <VerifiedIcon />
                </Box>
              )}
            </Box>
            <Typography
              variant="subtitle1"
              className={classNames(`line-clamp-1 ${styles.user__name} ${pathname !== '/home' && 'max-w-[175px]'}`)}
            >
              {getUserName()}
            </Typography>
            {open && (
              <CaretRight
                stroke="var(--midLightGray)"
                width="24px"
                height="24px"
                style={{ transform: 'rotate(270deg)' }}
              />
            )}
            {!open && (
              <CaretRight
                stroke="var(--midLightGray)"
                width="24px"
                height="24px"
                style={{ transform: 'rotate(90deg)' }}
              />
            )}
          </IconButton>
        )}

        {!isAuthenticated && !isDownMd && (
          <>
            <Box className="me-2">
              <UserLoggedOutIcon />
            </Box>

            <Typography
              className="cursor-pointer text-midLightGray hover:underline hover:text-deepYellow"
              onClick={() => onLogin()}
            >
              Login
            </Typography>

            <Divider orientation="vertical" className="mx-3" sx={{ borderColor: 'var(--magentaPink)' }} />

            <Typography className="text-midLightGray hover:underline hover:text-deepYellow" onClick={() => onSignup()}>
              Sign Up
            </Typography>
          </>
        )}

        {!isAuthenticated && isDownMd && (
          <>
            <Box onClick={() => setLoggedOutDrawer(true)}>
              <UserLoggedOutIcon />
            </Box>

            <LoggedOutDrawer loggedOutDrawer={loggedOutDrawer} setLoggedOutDrawer={setLoggedOutDrawer} />
          </>
        )}

        {isAuthenticated && isDownMd && (
          <>
            <Box className="relative" onClick={() => setLoggedInDrawer(true)}>
              <UserLoggedInIcon />
              <Box className="verified__box" id="verified-icon">
                <VerifiedIcon />
              </Box>
              {userHasMetadata(myUser) && pathname !== '/profile/verified' && (
                <Box className="verified__icon">
                  <VerifiedIcon />
                </Box>
              )}
            </Box>

            <LoggedInDrawer
              loggedInDrawer={loggedInDrawer}
              setLoggedInDrawer={setLoggedInDrawer}
              menuItems={responsiveMenuItems}
            />
          </>
        )}
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        disableScrollLock
      >
        {menuItems.map(menuItem => (
          <MenuItem onClick={menuItem.onClick} key={`menu-item-${menuItem.text}`}>
            <ListItemIcon>{menuItem.icon}</ListItemIcon>
            <Typography variant="body2" className="text-darkGray">
              {menuItem.text}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
