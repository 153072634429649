import { InputLabelProps as MuiInputLabelProps, InputLabel as MuiInputLabel } from '@mui/material';
import { Typography } from '../typography';
import classNames from 'classnames';

export const InputLabel = ({ children, disabled, ...rest }: MuiInputLabelProps) => {
  return (
    <MuiInputLabel {...rest} className={`${classNames(rest.className, 'mb-[6px] flex', disabled ? 'opacity-30' : '')}`}>
      <Typography variant="subtitle2">{children}</Typography>
    </MuiInputLabel>
  );
};
