import { getBlog } from 'services/orchestrator.service';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { getData } from 'utils';

export const useBlog = () => {
  const { id } = useParams();

  const { data: blog } = useQuery({
    queryKey: ['blog', id],
    queryFn: getData(() => getBlog({ rowId: id })),
    enabled: !!id,
  });

  return { blog };
};
