import { CategorySimpleModelDto } from 'otl-codegen/dist/marketplace-frontend';
import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useCategoryMenu = ({ setCurrentCategory, setSubCategories }) => {
  const [isMenuShown, setIsMenuShown] = useState<Boolean>(false);
  const navigate = useNavigate();
  const menuRef = useRef<any>(null);
  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  useEffect(() => {
    if (!isMenuShown) {
      setCurrentCategory(undefined);
      setSubCategories(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuShown]);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOutsideClick = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuShown(false);
    }
  };

  const onHoverListItem = (category: CategorySimpleModelDto) => {
    category.subCategories ? setSubCategories(category.subCategories) : setSubCategories(null);
    setCurrentCategory(category);
  };
  return {
    menuRef,
    isDownMd,
    navigate,
    isMenuShown,
    setIsMenuShown,
    onHoverListItem,
  };
};
