import styles from './styles.module.scss';
import { Button } from 'design-system';
import { Card } from '@mui/material';

const FilterButtons = ({ onReset, onApply }) => {
  return (
    <Card className={styles.filter___actions}>
      <Button size="small" sx={{ width: 213 }} onClick={onApply} label="Apply" />
      <Button size="small" variant="outlined" sx={{ ml: 1.5, width: 110 }} onClick={onReset} label="Reset" />
    </Card>
  );
};

export default FilterButtons;
