import {
  AuthenticateCustomerRequest,
  AuthenticateResponse,
  CatalogProductsCommandDto,
  CatalogProductsModelDto,
  CategoryModelDto,
  CategorySimpleModelDto,
  ProductDetailsAttributeChangeResponse,
  ProductDetailsModelDto,
  ProductOverviewModelDto,
  VendorModelDto,
} from 'otl-codegen/dist/marketplace-frontend';
import { UnreadMessageMailDto } from './dtos/message-center/UnreadMessageMail.dto';
import { QuotationsResponse } from './dtos/quotation/quotations.response';
import { StepThreeCreateRfqDto } from './dtos/rfq/StepThreeCreateRfq.dto';
import { CreateContactUsDto } from './dtos/hubspot/CreateContactUs.dto';
import { StepOneCreateRfqDto } from './dtos/rfq/StepOneCreateRfq.dto';
import { StepTwoCreateRfqDto } from './dtos/rfq/StepTwoCreateRfq.dto';
import { GetPromotionsDto } from './dtos/hubspot/GetPromotions.dto';
import { Blog, GetBlogsDto } from './dtos/hubspot/GetBlogs.dto';
import { CompleteUserDTO } from './dtos/auth/CompleteUser.dto';
import { Auth0GetUser } from './dtos/auth/Auth0GetUser.dto';
import { Industry } from './types/generic/industry-entity';
import { GetFaqsDto } from './dtos/hubspot/GetFaqs.dto';
import { RFQFile } from './types/rfq/RfqFile.type';
import { Query } from './types/generic/Query.dto';
import { RFQ } from './types/generic/rfq-entity';
import { GetAllResponse } from './types/generic';
import { EventData } from './dtos/events';
import agentkeepalive from 'agentkeepalive';
import axios from 'axios';

const keepAliveAgent = new agentkeepalive({
  maxSockets: 100,
  maxFreeSockets: 10,
  timeout: 60000,
});

export const orchestrator = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  httpAgent: keepAliveAgent,
  httpsAgent: keepAliveAgent,
});

// G-Recaptcha controller
export const gRecaptchaVerify = async (params: any) => {
  if (process.env.REACT_APP_GOOGLE_RECAPTCHA) {
    const { data } = await orchestrator.post<{
      success: boolean;
      challenge_ts?: string;
      hostname?: string;
      score?: number;
    }>('G-Recaptcha/verify/storefront', params);

    if (data.success && data.score && data.score > 0.4) return;
    throw new Error('Verification failed');
  }
};

// Products Controller
export const getHomePageProducts = () => {
  return orchestrator.get<ProductOverviewModelDto[]>(`products/HomePageProducts`);
};

export const getProductById = (id: number) => {
  return orchestrator.get<{ productDetailsModel: ProductDetailsModelDto }>(`products/GetProductDetails/${id}`);
};

export const getRelatedProducts = (id: number) => {
  return orchestrator.get<ProductOverviewModelDto[]>(`products/GetRelatedProducts/${id}`);
};

// Catalog Controller
export const getHomePageCategories = () => {
  return orchestrator.get<CategorySimpleModelDto[]>(`categories/HomePageCategories`);
};

export const getCategoryTree = () => {
  return orchestrator.get<CategorySimpleModelDto[]>(`categories/GetCategoryTree`);
};

export const getCatalogRoot = () => {
  return orchestrator.get<CategorySimpleModelDto[]>(`categories/GetCatalogRoot`);
};

export const getCategoryProducts = ({
  categoryId,
  body,
}: {
  categoryId: number;
  body?: Partial<CatalogProductsCommandDto>;
}) => {
  return orchestrator.post<{ catalogProductsModel: CatalogProductsModelDto }>(
    `categories/GetCategoryProducts/${categoryId}`,
    body,
  );
};

export const getCategoryById = ({
  categoryId,
  body,
}: {
  categoryId: number | undefined;
  body?: Partial<CatalogProductsCommandDto>;
}) => {
  return orchestrator.post<{ categoryModelDto: CategoryModelDto }>(`categories/GetCategory/${categoryId}`, body);
};

// Vendor Controller
export const getVendorProducts = ({
  vendorId,
  body,
}: {
  vendorId: number;
  body?: Partial<CatalogProductsCommandDto>;
}) => {
  return orchestrator.post<{ catalogProductsModel: CatalogProductsModelDto }>(
    `vendor/Storefront/GetVendorProducts/${vendorId}`,
    body,
  );
};

export const getVendorById = (id: number) => {
  return orchestrator.post<VendorModelDto>(`vendor/GetVendor/${id}`);
};

export const getTopVendors = () => {
  return orchestrator.get<VendorModelDto[]>(`vendor/top-vendors`);
};
export const getVendorByAuthorizedEmail = async (token: string, quotationCreator?: string) => {
  return orchestrator.get<VendorModelDto>(`/vendor/GetVendorByAuthorizedEmail?email=${quotationCreator}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// ShoppingCart Controller
export const updateProductDetailsAttribute = ({
  productId,
  body,
}: {
  productId: number;
  body?: Record<string, number>;
}) => {
  return orchestrator.put<ProductDetailsAttributeChangeResponse>(
    `shopping-cart/ProductDetailsAttributeChange/${productId}`,
    body,
  );
};

// RFQ Controller
export const getRfqSignedUrl = ({ token, fileName }: { token: string; fileName: string }) => {
  return orchestrator.get<string>(`rfq/getSignedUrl/${fileName}`, {
    headers: {
      rfqId: token,
    },
  });
};

export const createRfq = (body: StepOneCreateRfqDto) => {
  return orchestrator.post<RFQ>('rfq/stepped', body);
};

export const updateSecondStepRfq = ({ body, token }: { body: StepTwoCreateRfqDto; token: string }) => {
  return orchestrator.patch<RFQ>(`rfq/stepped/second-step`, body, {
    headers: {
      rfqId: token,
    },
  });
};

export const updateThirdStepRfq = ({ body, token }: { body: StepThreeCreateRfqDto; token: string }) => {
  return orchestrator.patch<RFQ>(`rfq/stepped/third-step`, body, {
    headers: {
      rfqId: token,
    },
  });
};

export const getRfqByToken = (token: string) => {
  return orchestrator.get<RFQ>(`rfq/token`, {
    headers: {
      rfqId: token,
    },
  });
};

export const getRfqIndustries = () => {
  return orchestrator.post<GetAllResponse<Industry>>('industry/find', {});
};

export const getMyRfq = ({ body, token }: { body: Query; token: string }) => {
  return orchestrator.post<GetAllResponse<RFQ>>('rfq/my-rfq', body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getRfqDetails = (token: string, id: string) => {
  return orchestrator.get<RFQ>(`rfq/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getRfqFiles = (token: string, rfqId: string) => {
  return orchestrator.get<RFQFile[]>(`rfq/getFiles/${rfqId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const cancelRfq = (token: string, rfqId: string) => {
  return orchestrator.patch(
    `rfq/cancel/${rfqId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

// Quotation Controller
export const acceptQuotation = (token: string, quotationId: string) => {
  return orchestrator.patch(
    `quotation/${quotationId}/accept`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const rejectQuotation = (token: string, quotationId: string, body: { reason?: string }) => {
  return orchestrator.patch(`quotation/${quotationId}/reject`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getBuyerRfqQuotations = (token: string, rfqId: string) => {
  return orchestrator.get<QuotationsResponse>(`quotation/buyer-quotations/${rfqId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Authentication Controller
export const getToken = (body: AuthenticateCustomerRequest) => {
  return orchestrator.post<AuthenticateResponse>(`authentication/GetToken`, body);
};

// Sendbird Controller
export const getSendbirdSessionToken = async ({ auth0Token }: { auth0Token: string }) => {
  const res = await orchestrator.get<{ token: string; expires_at: number }>('sendbird/token', {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
    },
  });
  return res.data;
};
export const removeUserFromChannel = async (auth0Token: string, channelUrl: string, userId: string) => {
  const res = await orchestrator.put(
    'sendbird/removeUser',
    { channelUrl, userId },
    {
      headers: {
        Authorization: `Bearer ${auth0Token}`,
      },
    },
  );
  return res.data;
};

// Hub-Db Controller
export const getPromotions = () => {
  return orchestrator.get<GetPromotionsDto>('hub-spot/promotions');
};
export const getBlogs = () => {
  return orchestrator.get<GetBlogsDto>('hub-spot/blogsMarketplace');
};
export const getBlog = ({ rowId }: { rowId: string | undefined }) => {
  return orchestrator.get<Blog>(`hub-spot/blogById/${rowId}`);
};
export const getFaqs = () => {
  return orchestrator.get<GetFaqsDto>('hub-spot/faqs');
};

// contact us controller
export const submitContactUs = (body: CreateContactUsDto) => {
  return orchestrator.post('hub-spot/contact-us', body);
};

// message center controller
export const registerMessage = (body: UnreadMessageMailDto, token: string) => {
  return orchestrator.post<UnreadMessageMailDto>('messaging-center/register', body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const unregisterMessage = (body: UnreadMessageMailDto, token: string) => {
  console.log('unregisterMessage', body);
  return orchestrator.post<UnreadMessageMailDto>('messaging-center/un-register', body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// User management controller
export const completeProfile = ({ token, body }: { token: string; body: CompleteUserDTO }) => {
  return orchestrator.patch('auth/complete-profile-buyer', body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getUser = (token: string | undefined) => {
  if (token) {
    return orchestrator.get<Auth0GetUser>(`auth/get-user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
};

export const getEvents = () => orchestrator.get<EventData[]>(`hub-spot/events`);
