import { ReactComponent as FilterIcon } from 'design-system/public/icons/filter-narrow.svg';
import { ResultsHeader, PageSize, NopHits, CustomSlider, NopSort } from 'components';
import { Button, Loader, Pagination, Typography } from 'design-system';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import styles from '../../../category/styles.module.scss';
import { useVendorProducts } from './useVendorProducts';
import { useRef } from 'react';
import './styles.scss';

export const VendorProductsTab = () => {
  const {
    vendorId,
    categoryProductsResponse,
    pageSizeOptions,
    itemsCount,
    pagesCount,
    pageNumber,
    pageSize,
    setPageSize,
    orderBy,
    availableSortOptions,
    setOrderBy,
    handleChangePage,
    priceRange,
    setPriceRange,
    handleFilterRequest,
  } = useVendorProducts();

  const productsRef = useRef<HTMLDivElement>(null);
  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  if (!categoryProductsResponse) return <Loader />;
  return (
    <div ref={productsRef}>
      <Grid container spacing={{ xs: 0, md: 3 }} className="mt-6 max-sm:mt-3">
        {!isDownMd && (
          <Grid item xs={12} md={2.5}>
            <Box className={styles.filter__box}>
              <Typography variant="h4" component="h4" className="text-darkGray section-subtitle mb-8">
                Filter Items
              </Typography>

              <Typography variant="h6" component="h6" className="text-darkGray section-subtitle mb-8">
                Price Range
              </Typography>

              <CustomSlider
                min={0}
                max={categoryProductsResponse?.catalogProductsModel?.priceRangeFilter?.availablePriceRange.to || 10000}
                value={priceRange}
                onChange={setPriceRange}
              />

              <Button
                size="small"
                className="mt-5"
                startIcon={<FilterIcon stroke="var(--white)" />}
                onClick={handleFilterRequest}
                label="Filter"
              />
            </Box>
          </Grid>
        )}

        <Grid item xs={12} md={9.5}>
          <ResultsHeader
            itemsCount={itemsCount}
            pageSizeComponent={
              <PageSize
                key={`category-${vendorId}-page-size`}
                pageSize={pageSize.toString()}
                pageSizeOptions={pageSizeOptions}
                setPageSize={setPageSize}
              />
            }
            sortComponent={
              <NopSort
                key={`category-${vendorId}-sort`}
                currentValue={orderBy}
                availableSortOptions={availableSortOptions}
                setOrderBy={setOrderBy}
                defaultValue={orderBy}
              />
            }
            onFilter={handleFilterRequest}
            categoryProductsResponse={categoryProductsResponse}
            priceRange={priceRange}
            setPriceRange={setPriceRange}
          />

          <NopHits hits={categoryProductsResponse?.catalogProductsModel?.products} />

          <Box className={isDownMd ? 'mt-6 mb-12' : 'my-[60px]'}>
            <Pagination
              key={`category-${vendorId}-pagination`}
              count={pagesCount}
              page={pageNumber}
              handleChangePage={val => {
                handleChangePage(val);
                setTimeout(() => {
                  productsRef.current?.scrollIntoView({
                    behavior: 'smooth',
                    inline: 'start',
                  });
                }, 150);
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
