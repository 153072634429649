import { useMediaQuery, AppBar, useTheme, Hidden, Divider } from '@mui/material';
import { ResponsiveSecondaryHeader } from '../responsive-secondary-header';
import { OtherSecondaryHeader } from '../other-secondary-header';
import { ResponsiveMainHeader } from '../responsive-main-header';
import { HomeSecondaryHeader } from '../home-secondary-header';
import { BreadcrumbHeader, CustomToolbar } from 'components';
import { OtherMainHeader } from '../other-main-header';
import { HomeMainHeader } from '../home-main-header';
import { VerifiedHeader } from '../verified-header';
import { useLocation } from 'react-router-dom';
import { EventHeader } from '../event-header';
import { AppContainer } from 'design-system';

export const HeaderHandler = () => {
  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));
  const { pathname } = useLocation();

  return (
    <>
      <AppBar
        position="sticky"
        elevation={isDownMd ? 1 : 0}
        sx={{ backgroundColor: isDownMd ? 'white' : 'var(--background)', borderRadius: 0 }}
      >
        <Hidden mdDown>
          {pathname === '/home' && (
            <CustomToolbar disableGutters className="flex flex-col pt-0">
              <EventHeader />
              <VerifiedHeader />
              <HomeMainHeader />
              <HomeSecondaryHeader />
            </CustomToolbar>
          )}

          {pathname !== '/home' && (
            <>
              <CustomToolbar disableGutters className="flex flex-col py-0 bg-white">
                <OtherMainHeader />
                <Divider sx={{ borderColor: 'var(--antiFlashWhite)', width: '100%' }} />

                <OtherSecondaryHeader />
              </CustomToolbar>
              <Divider sx={{ borderColor: 'var(--antiFlashWhite)', width: '100%' }} />
            </>
          )}
        </Hidden>

        <Hidden mdUp>
          <CustomToolbar disableGutters className="flex flex-col">
            <AppContainer>
              <ResponsiveMainHeader />

              <ResponsiveSecondaryHeader />
            </AppContainer>
          </CustomToolbar>

          <EventHeader />
          <VerifiedHeader />
        </Hidden>
      </AppBar>
      <BreadcrumbHeader />
    </>
  );
};
