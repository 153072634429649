import { Typography } from 'src/components/typographies/typography';
import { Box, Card, CardContent, CardProps } from '@mui/material';
import { getUnitShort } from 'src/utils/helpers/getUnitShort';
import styles from './styles.module.css';

type ProductCardProps = {
  image: any;
  pName: any;
  price: any;
  unit: any;
} & CardProps;

export const ProductCard = ({ id, image, pName, price, unit, ...props }: ProductCardProps) => {
  return (
    <Card className={styles.product__card} elevation={1} {...props}>
      <div className={styles.product__media__container}>
        <img src={image} alt="" />
      </div>

      <CardContent className={styles.product__card__content}>
        <Typography
          variant="subtitle2"
          className="text-darkGray mb-3 mt-6 line-clamp-2 leading-6 min-h-[48px] max-sm:leading-4 max-sm:min-h-[32px] max-sm:mb-2 max-sm:mt-3"
        >
          {pName}
        </Typography>

        {price && unit && (
          <Box className="flex items-center">
            <Typography variant="h6" component="h6" className="text-magentaPink">
              {price}
            </Typography>
            {unit && (
              <Typography variant="caption" className="text-midLightGray line-clamp-1 leading-7">
                &nbsp;&nbsp;/&nbsp; {getUnitShort(unit) ? `${getUnitShort(unit)}` : unit}
              </Typography>
            )}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
