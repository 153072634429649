import { getRfqByToken } from 'services/orchestrator.service';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useCheckRfq } from './useCheckRfq';
import { useEffect } from 'react';
import { getData } from 'utils';

export const useRfqByToken = ({ token, setPageLoading }) => {
  const navigate = useNavigate();

  const { checkRfq } = useCheckRfq();
  const { data: rfqByTokenRes } = useQuery({
    queryKey: ['rfqByToken', token],
    queryFn: getData(() => getRfqByToken(token)),
    enabled: !!token,
    retry: false,
  });

  useEffect(() => {
    const decodedToken: JwtPayload = jwtDecode(token);
    if (decodedToken.exp! * 1000 < Date.now()) {
      navigate(`/rfq/email-expired`);
    } else checkRfq(rfqByTokenRes, token, setPageLoading);
  }, [checkRfq, navigate, rfqByTokenRes, setPageLoading, token]);
};
