import { getVendorById } from 'services/orchestrator.service';
import { useQuery } from '@tanstack/react-query';
import { getData } from 'utils';

export const useGetVendorById = ({ vendorId }) => {
  const {
    data: vendorDetails,
    isLoading: isVendorDetailsLoading,
    isError: isErrorVendorDetails,
  } = useQuery({
    queryKey: ['vendorDetails', vendorId],
    queryFn: getData(() => getVendorById(+vendorId!)),
    enabled: !!vendorId,
  });

  return {
    vendorDetails,
    isVendorDetailsLoading,
    isErrorVendorDetails,
  };
};
