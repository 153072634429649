import { Typography } from '../typography';

export interface ErrorFeedbackProps {
  message: string | undefined;
}

export const ErrorFeedback = ({ message }: ErrorFeedbackProps) => {
  return (
    <Typography variant="body2" className="mt-3 text-red">
      {message}
    </Typography>
  );
};
