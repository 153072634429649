import { ReactComponent as Location } from 'design-system/public/icons/location.svg';
import { Typography } from 'design-system';
import { Box } from '@mui/material';

export const FindUsLocation = ({ flag, disc }) => {
  return (
    <Box className="flex items-center max-md:items-start pt-5 gap-3">
      <Location className="shrink-0 grow-1" />
      {flag}
      <Typography variant="body1" className="text-midDarkGray ">
        {disc}
      </Typography>
    </Box>
  );
};
