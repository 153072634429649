export enum RfqStatusEnum {
  Ready = 'Ready',
  Active = 'Active',
  Closed = 'Closed',
  Expired = 'Expired',
  Draft = 'Draft',
  Incomplete = 'Incomplete',
}

export enum QuotationStatusEnum {
  New = 'New',
  Expired = 'Expired',
  Won = 'Won',
  Lost = 'Lost',
}

export enum ShipmentTermEnum {
  'Ex Works (EXW)' = 'Ex Works (EXW)',
  'Free On Board (FOB)' = 'Free On Board (FOB)',
  'Cost and Freight (CFR)' = 'Cost and Freight (CFR)',
  'Cost Insurance and Freight (CIF)' = 'Cost Insurance and Freight (CIF)',
  'Carriage Paid To (CPT)' = 'Carriage Paid To (CPT)',
  'Carriage and Insurance Paid To (CIP)' = 'Carriage and Insurance Paid To (CIP)',
}

export enum UnitEnum {
  'Acre' = 'Acre',
  'Ampere' = 'Ampere',
  'Bag' = 'Bag',
  'Barrel' = 'Barrel',
  'Blade' = 'Blade',
  'Box' = 'Box',
  'Bushel' = 'Bushel',
  'Carat' = 'Carat',
  'Carton' = 'Carton',
  'Case' = 'Case',
  'Centimeter' = 'Centimeter',
  'Chain' = 'Chain',
  'Coil' = 'Coil',
  'Combo' = 'Combo',
  'CRTG' = 'CRTG',
  'Cubic Centimeter' = 'Cubic Centimeter',
  'Cubic Feet' = 'Cubic Feet',
  'Cubic Inche' = 'Cubic Inche',
  'Cubic Meter' = 'Cubic Meter',
  'Cubic Yard' = 'Cubic Yard',
  'Celsius' = 'Celsius',
  'Fahrenheit' = 'Fahrenheit',
  'Dozens' = 'Dozens',
  'Drams' = 'Drams',
  'Fluid Ounce' = 'Fluid Ounce',
  'Feet' = 'Feet',
  'Forty-foot Container' = 'Forty-foot Container',
  'Furlong' = 'Furlong',
  'Gallon' = 'Gallon',
  'Gill' = 'Gill',
  'Grain' = 'Grain',
  'Gram' = 'Gram',
  'Gross' = 'Gross',
  'Hectare' = 'Hectare',
  'Hertz' = 'Hertz',
  'Inche' = 'Inche',
  'Kiloampere' = 'Kiloampere',
  'Kilogram' = 'Kilogram',
  'Kilohertz' = 'Kilohertz',
  'Kilometer' = 'Kilometer',
  'Kiloohm' = 'Kiloohm',
  'Kilovolt' = 'Kilovolt',
  'Kilowatt' = 'Kilowatt',
  'Liter' = 'Liter',
  'Long Ton' = 'Long Ton',
  'Megahertz' = 'Megahertz',
  'Meter' = 'Meter',
  'Metric Ton' = 'Metric Ton',
  'Mile' = 'Mile',
  'Milliampere' = 'Milliampere',
  'Milligram' = 'Milligram',
  'Millihertz' = 'Millihertz',
  'Milliliter' = 'Milliliter',
  'Millimeter' = 'Millimeter',
  'Milliohm' = 'Milliohm',
  'Millivolt' = 'Millivolt',
  'Milliwatt' = 'Milliwatt',
  'Nautical Mile' = 'Nautical Mile',
  'Ohms' = 'Ohms',
  'Ounce' = 'Ounce',
  'Pack' = 'Pack',
  'Pair' = 'Pair',
  'Pallet' = 'Pallet',
  'Panel' = 'Panel',
  'Parcel' = 'Parcel',
  'Perche' = 'Perche',
  'Piece' = 'Piece',
  'Pint' = 'Pint',
  'Pipe' = 'Pipe',
  'Plant' = 'Plant',
  'Pole' = 'Pole',
  'Pound' = 'Pound',
  'Quart' = 'Quart',
  'Quarter' = 'Quarter',
  'Rod' = 'Rod',
  'Roll' = 'Roll',
  'Set' = 'Set',
  'Sheet' = 'Sheet',
  'Short Ton' = 'Short Ton',
  'Square Centimeter' = 'Square Centimeter',
  'Square Feet' = 'Square Feet',
  'Square Inche' = 'Square Inche',
  'Square Meter' = 'Square Meter',
  'Square Mile' = 'Square Mile',
  'Square Yard' = 'Square Yard',
  'Stone' = 'Stone',
  'Strand' = 'Strand',
  'Ton' = 'Ton',
  'Tonne' = 'Tonne',
  'Tray' = 'Tray',
  'Twenty-foot Container' = 'Twenty-foot Container',
  'Unit' = 'Unit',
  'Volt' = 'Volt',
  'Watt' = 'Watt',
  'Yard' = 'Yard',
}

export enum ProductAttributeEnum {
  Colors = 1,
  Size = 2,
  Thickness = 3,
  Finish = 4,
}

export enum SignUpEnum {
  Buyer = 'buyer',
  Seller = 'seller',
}
