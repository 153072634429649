import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Button, Loader, Typography, VendorCard } from 'design-system';
import { VendorProductsTab } from './components/vendor-products-tab';
import { useVendorDetails } from './useVendorDetails';
import { ArrowForward } from '@mui/icons-material';
import vrImg from '../../assets/images/VR.svg';
import { Navigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';
import { htmlToText } from 'utils';

export const VendorDetails = () => {
  const {
    vendorDetails,
    onContactSeller,
    isDownMd,
    descriptionTextResp,
    fullDescriptionOpen,
    setFullDescriptionOpen,
    isErrorVendorDetails,
    isVendorDetailsLoading,
  } = useVendorDetails();

  if (isVendorDetailsLoading) return <Loader />;
  if (isErrorVendorDetails) return <Navigate to="/error" />;

  return (
    <>
      {vendorDetails && (
        <Helmet>
          <title>{vendorDetails.metaTitle || vendorDetails.name}</title>
          <meta name="description" content={vendorDetails.metaDescription ? vendorDetails.metaDescription : ''} />
          <meta name="keywords" content={vendorDetails.metaKeywords ? vendorDetails.metaKeywords : ''} />
        </Helmet>
      )}
      {vendorDetails && (
        <div className={styles.vendor__section} key={`Vendor ${vendorDetails?.id}`}>
          <div className={styles.vendor__section__info}>
            <Box className={styles.vendor__card}>
              <VendorCard
                vendor={{
                  id: vendorDetails.id,
                  pictureModel: {
                    fullSizeImageUrl: vendorDetails.pictureModel.fullSizeImageUrl || '',
                    alternateText: vendorDetails.pictureModel.alternateText || '',
                  },
                }}
                sx={{ borderRadius: '8px !important', padding: '0 !important', border: '0 !important' }}
              />
            </Box>

            <Box>
              <div className="mb-3 max-sm:mb-1">
                <Typography variant="h4" component="h4" className="text-white max-sm:mb-1 line-clamp-1">
                  {vendorDetails?.name}
                </Typography>

                {vendorDetails?.since && (
                  <Typography variant="caption" className="text-secondaryLight">
                    Since {vendorDetails?.since}
                  </Typography>
                )}
              </div>

              {vendorDetails?.description && (
                <>
                  {!isDownMd && (
                    <Typography variant="body2" className="text-white">
                      {htmlToText(vendorDetails?.description)}
                    </Typography>
                  )}

                  {isDownMd && (
                    <>
                      <Typography variant="body2" className="text-white">
                        {descriptionTextResp}
                        <Box className="inline-flex items-center">
                          <Typography
                            variant="body2"
                            className="underline text-white cursor-pointer font-bold ms-2"
                            onClick={() => setFullDescriptionOpen(!fullDescriptionOpen)}
                            component="span"
                          >
                            {fullDescriptionOpen ? 'View Less' : 'View More'}
                          </Typography>
                          <Box component="span">
                            {fullDescriptionOpen ? (
                              <KeyboardArrowUp style={{ width: '15px', height: '15px' }} />
                            ) : (
                              <KeyboardArrowDown style={{ width: '15px', height: '15px' }} />
                            )}
                          </Box>
                        </Box>
                      </Typography>
                    </>
                  )}
                </>
              )}
            </Box>
          </div>

          <Box className={styles.vendor__section__actions}>
            <Button
              size="small"
              color="inherit"
              onClick={onContactSeller}
              endIcon={<ArrowForward />}
              className={styles.vendor__contact}
              label="Negotiate"
            />
            {vendorDetails.vrLink && (
              <a href={vendorDetails.vrLink} target="_blank" rel="noopener noreferrer">
                <Button
                  size="small"
                  color="inherit"
                  className={styles.vendor__vr}
                  endIcon={<img src={vrImg} alt="vr" />}
                  label="View VR"
                />
              </a>
            )}
          </Box>
        </div>
      )}

      {vendorDetails && <VendorProductsTab />}
    </>
  );
};
