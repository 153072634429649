import { Button, Typography, getUnitShort } from 'design-system';
import { AutocompleteState } from '@algolia/autocomplete-core';
import { getCollectionBySourceId } from '../../functions';
import { useMediaQuery, useTheme } from '@mui/material';
import { SectionHeader } from '../section-header';
import { Highlight } from '../highlight';
import { ProductHit } from '../../types';
import { Link } from 'react-router-dom';
import { cx } from '../../utils';
import './styles.scss';

type ProductItemProps = {
  hit: ProductHit;
};

type Props = {
  autocomplete: any;
  autocompleteState: AutocompleteState<any>;
};

const ProductItem = ({ hit }: ProductItemProps) => {
  return (
    <Link
      to={`/product-details/${hit.objectID}/${hit.seName}`}
      className="aa-ItemLink aa-ProductItem"
      state={{ fromSearch: true, hitName: hit.name }}
    >
      <div className="aa-ItemContent">
        <div
          className={cx('aa-ItemPicture')}
          style={{
            backgroundImage: `url(${hit.image})`,
          }}
        />

        <div className="aa-ItemContentBody">
          <div className="w-full">
            {hit.vendor && (
              <div className="aa-ItemContentBrand">
                <Typography variant="body2" className="text-midLightGray line-clamp-1">
                  <Highlight hit={hit} attribute="vendor" />
                </Typography>
              </div>
            )}
            <div className="aa-ItemContentTitleWrapper">
              <div className="aa-ItemContentTitle">
                <Typography variant="subtitle2" className="text-darkGray line-clamp-1">
                  <Highlight hit={hit} attribute="name" />
                </Typography>
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="aa-ItemContentPrice">
              <div className="aa-ItemContentPriceCurrent">
                <Typography variant="h6" component="h6" className="text-magenta">
                  ${hit.price}
                </Typography>
                {hit.unit && (
                  <Typography variant="caption" className="text-midLightGray line-clamp-1 leading-7">
                    &nbsp;&nbsp;/&nbsp; {getUnitShort(hit.unit) ? `${getUnitShort(hit.unit)}` : hit.unit}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export const Products = ({ autocomplete, autocompleteState }: Props) => {
  const products = getCollectionBySourceId(autocompleteState, 'productsPlugin');
  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  return (
    <>
      {products && products.items.length > 0 && (
        <>
          <SectionHeader title={`Products for ${autocompleteState.query}`} />

          <div className="aa-PanelSection--products">
            <div className="aa-PanelSectionSource">
              <div className="aa-Source">
                <ul className="aa-List" {...autocomplete.getListProps()}>
                  {products.items.map((item, idx) => {
                    return (
                      <li
                        key={`product-item-${idx}`}
                        className="aa-Item"
                        id={`search-result-product-card-#${(idx + 1).toString()}`}
                      >
                        <ProductItem hit={item} />
                      </li>
                    );
                  })}
                </ul>

                {(autocompleteState.context.nbProducts as number) > 4 && (
                  <div className="aa-SourceFooter">
                    <div style={{ textAlign: 'center' }}>
                      <Link
                        to={`/products?search=${autocompleteState.query}`}
                        state={{ searchName: autocompleteState.query }}
                      >
                        <Button
                          size={isDownMd ? 'small' : 'medium'}
                          className="mt-4"
                          label={`See All Products ${autocompleteState.context.nbProducts}`}
                        />
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
