import { Box, Hidden, MenuItem, Select, SelectProps, Typography } from '@mui/material';
import { ExpandLess, ExpandMore, Sort } from '@mui/icons-material';
import { useState } from 'react';

type SortProps = {
  availableOptions: any[];
  currentValue: any;
  refine: any;
  canRefine: boolean;
} & SelectProps;

// Todo: use Select of design system instead
export const AlgoliaSort = ({ availableOptions, currentValue, refine, canRefine, ...props }: SortProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const foundOption = availableOptions.find(option => option.value === currentValue);

  const handleExpandClick = () => {
    const disabled = props.disabled;
    !disabled && setOpen(!open);
  };

  return (
    <Select
      open={open}
      sx={{ width: 'auto', fontSize: '13px', height: '40px' }}
      defaultValue={props.defaultValue}
      variant="standard"
      disableUnderline
      renderValue={() => {
        return (
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Sort className="text-midLightGray" />

            <Hidden mdDown>
              <Typography className="text-midLightGray" variant="body1">
                Sort by:
              </Typography>{' '}
              <Typography className="text-darkGray" variant="body1">
                {foundOption && foundOption.label}
              </Typography>
            </Hidden>

            <Box className="cursor-pointer text-midLightGray" onClick={() => handleExpandClick()}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </Box>
          </Box>
        );
      }}
      onChange={event => refine(event.target.value)}
      disabled={!canRefine}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      IconComponent={() => <></>}
      MenuProps={{
        sx: {
          '&& .Mui-selected': {
            backgroundColor: 'var(--secondaryLight)',
          },
        },
      }}
    >
      {availableOptions &&
        availableOptions.map(
          option =>
            option.value && (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ),
        )}
    </Select>
  );
};
