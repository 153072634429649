import { ReactComponent as CaretRight } from 'design-system/public/icons/caret-right.svg';
import { Swiper, SwiperClass, SwiperProps, SwiperSlide } from 'swiper/react';
import { TierPriceModelDto } from 'otl-codegen/dist/marketplace-frontend';
import { FreeMode, Navigation } from 'swiper/modules';
import { HTMLAttributes, useRef } from 'react';
import { formulateTierPrices } from 'utils';
import { Typography } from 'design-system';
import { Box } from '@mui/material';
import './styles.scss';

type Props = {
  tierPrices: TierPriceModelDto[] | null;
  unit: string;
} & HTMLAttributes<HTMLDivElement>;

export const TierPrices: React.FC<Props> = ({ tierPrices, unit, ...props }) => {
  const tierPricesFinal = formulateTierPrices(tierPrices);
  const sliderRef = useRef<SwiperClass>();

  const tierPricesSwiperProps: SwiperProps = {
    direction: 'horizontal',
    spaceBetween: 12,
    slidesPerView: 'auto',
    modules: [FreeMode, Navigation],
    slideToClickedSlide: true,
    navigation: {
      prevEl: '.category-swiper.custom-swiper-button-prev',
      nextEl: '.category-swiper.custom-swiper-button-next',
      disabledClass: 'disabled-swiper',
    },
    onSwiper: swiper => {
      sliderRef.current = swiper;
    },
    loop: false,
  };

  return (
    <Box {...props}>
      {/* <Slider {...settings}>
        {tierPricesFinal?.map((tierPrice, idx) => (
          <Box key={`tier-price-#${idx}`} id="slider-box">
            <Typography variant="body2" component="span" className="text-midLightGray">
              {tierPrice.tierRange}&nbsp;
            </Typography>
            <Typography variant="subtitle1" component="span" className="text-darkGray">
              {tierPrice.price}
            </Typography>
          </Box>
        ))}
      </Slider> */}

      <div className="swiper-with-arrows">
        <div className="category-swiper custom-swiper-button-prev" onClick={() => sliderRef?.current?.slidePrev()}>
          <CaretRight />
        </div>

        <Swiper {...tierPricesSwiperProps}>
          {tierPricesFinal?.map((tierPrice, idx) => (
            <SwiperSlide style={{ width: 'auto', padding: '2px' }} key={`tierPrice-slide-${idx}`}>
              <Box key={`tier-price-#${idx}`} id="slider-box">
                <Typography variant="body2" component="span" className="text-midLightGray">
                  {tierPrice.tierRange}&nbsp;
                </Typography>
                <Typography variant="subtitle1" component="span" className="text-darkGray">
                  {tierPrice.price}
                </Typography>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="category-swiper custom-swiper-button-next" onClick={() => sliderRef?.current?.slideNext()}>
          <CaretRight />
        </div>
      </div>
    </Box>
  );
};
