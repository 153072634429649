import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { Typography } from 'design-system';
import { Helmet } from 'react-helmet';
import { BlogGrid } from 'components';
import { useEffect } from 'react';
import { useBlogs } from 'hooks';

export const Blogs = () => {
  const { updateBreadcrumbs } = useBreadcrumbs();
  const { blogs } = useBlogs();

  useEffect(() => {
    updateBreadcrumbs([{ label: 'Blogs', link: '/blogs' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Insights & Updates about the industry</title>
        <meta
          name="description"
          content="Stay informed with our latest blog posts on industry trends and company news."
        />
        <meta
          name="keywords"
          content="African Continental Free Trade, AfCFTA, free trade, African Union Commission, Africa’s economic strategies, best African companies, africa market place, african sellers , best price in Africa, shipping steps from Africa, african b2b marketplace, b2b portals, online b2b marketplace, b2b suppliers, b2b trading"
        />
      </Helmet>
      <Typography variant="h4" component="h4" className="mb-6 max-sm:mb-3">
        Blogs
      </Typography>

      <BlogGrid blogResults={blogs?.results} />
    </>
  );
};
