import { ReactComponent as PerPageIcon } from 'design-system/public/icons/per-page.svg';
import { Select, MenuItem, Box, Hidden } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useHitsPerPage } from 'react-instantsearch';
import { Typography } from 'design-system';
import { useState } from 'react';

// Todo: use Select of design system instead
export const AlgoliaHitsPerPage = ({ ...props }) => {
  const [open, setOpen] = useState(false);
  const pageSizeOptions = [
    { label: '40 hits per page', value: 40, default: true },
    { label: '60 hits per page', value: 60 },
    { label: '100 hits per page', value: 100 },
  ];
  const { items, refine, canRefine } = useHitsPerPage({
    items: pageSizeOptions,
  });
  const { value } = items.find(({ isRefined }) => isRefined) || {};

  const handleExpandClick = () => {
    const disabled = props.disabled;
    !disabled && setOpen(!open);
  };

  return (
    <Select
      open={open}
      sx={{ width: 'auto', fontSize: '13px', height: '40px' }}
      variant="standard"
      disableUnderline
      defaultValue={40}
      renderValue={() => {
        return (
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <PerPageIcon />

            <Hidden mdDown>
              <Typography className="text-midLightGray" variant="body1">
                Show:
              </Typography>{' '}
              <Typography className="text-darkGray" variant="body1">
                {value}
              </Typography>
            </Hidden>

            <Hidden mdUp>
              <Typography className="text-darkGray" variant="body1">
                {value}
              </Typography>
            </Hidden>

            <Box className="cursor-pointer text-midLightGray" onClick={() => handleExpandClick()}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </Box>
          </Box>
        );
      }}
      onChange={event => {
        refine(+event.target.value);
      }}
      disabled={!canRefine}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      IconComponent={() => <></>}
      MenuProps={{
        sx: {
          '&& .Mui-selected': {
            backgroundColor: 'var(--secondaryLight)',
          },
        },
      }}
    >
      {items &&
        items.map(
          option =>
            option.value && (
              <MenuItem key={option.value} value={option.value}>
                {option.value}
              </MenuItem>
            ),
        )}
    </Select>
  );
};
