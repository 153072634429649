import { ReactComponent as AppsIcon } from 'design-system/public/icons/apps.svg';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useCategoryMenu } from './useCategoryMenu';
import { Button, Typography } from 'design-system';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { useCategoryTree } from 'hooks';
import { Box } from '@mui/material';

export const CategoriesMenu = () => {
  const { categoryTree, subCategories, setSubCategories, currentCategory, setCurrentCategory } = useCategoryTree();
  const { menuRef, isDownMd, navigate, isMenuShown, setIsMenuShown, onHoverListItem } = useCategoryMenu({
    setCurrentCategory,
    setSubCategories,
  });

  return (
    <Box ref={menuRef} className={styles.main__container}>
      <Button
        variant="text"
        color="inherit"
        className="text-midDarkGray relative"
        startIcon={<AppsIcon />}
        endIcon={
          isDownMd ? '' : isMenuShown ? <KeyboardArrowUp className="-z-10" /> : <KeyboardArrowDown className="-z-10" />
        }
        onClick={() => (isDownMd ? navigate('/all-categories-list') : setIsMenuShown(!isMenuShown))}
        label="All Categories"
      />

      {isMenuShown && (
        <Box className={styles.categories__container}>
          <Box className={styles.partition__container__left} sx={{ width: '300px' }}>
            {categoryTree &&
              categoryTree?.map(category => (
                <Link
                  key={`category-${category.id}#`}
                  to={`category/${category.id}/${category.seName}`}
                  className={styles.category__item}
                  onClick={() => setIsMenuShown(false)}
                  onMouseOver={() => onHoverListItem(category)}
                >
                  <Typography variant="body1">{category.name}</Typography>
                  <Box className={styles.vertical__selector} />
                </Link>
              ))}
          </Box>

          <Box sx={{ width: '240px' }} className={styles.partition__container__right}>
            {currentCategory && (
              <Link
                to={`/category/${currentCategory && currentCategory.id}/${currentCategory.seName}`}
                onClick={() => setIsMenuShown(false)}
                className={styles.sub__category__item}
              >
                <Typography variant="body1"> View All</Typography>
                <Box className={styles.vertical__selector} />
              </Link>
            )}
            {subCategories?.map(subCategory => (
              <Link
                to={`/category/${subCategory.id}/${subCategory.seName}`}
                onClick={() => setIsMenuShown(false)}
                className={styles.sub__category__item}
              >
                <Typography variant="body1">{subCategory.name}</Typography>
                <Box className={styles.vertical__selector} />
              </Link>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};
