import { CategorySimpleModelDto } from 'otl-codegen/dist/marketplace-frontend';
import { getCatalogRoot } from 'services/orchestrator.service';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { Box, Card, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';

export const AllCategories = () => {
  const [catalogRoot, setCatalogRoot] = useState<CategorySimpleModelDto[]>([]);
  const { updateBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    updateBreadcrumbs([{ label: 'All Categories', link: '/all-categories' }]);
    getCatalogRoot().then(({ data }) => {
      setCatalogRoot(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Grid container spacing={{ xs: 1.5, md: 3 }}>
        {catalogRoot.map(category => (
          <Grid item xs={6} md={3}>
            <Link
              key={`category-#${category.id}`}
              to={`/category/${category.id}/${category.seName}`}
              state={{ fromSearch: false }}
            >
              <Card className={styles.category__card}>
                <Box
                  className={styles.category__image}
                  sx={{ backgroundImage: `url(${category?.pictureModel?.fullSizeImageUrl})` }}
                >
                  <Box className={styles.category__tile__button}>
                    <img src={category?.iconModel?.fullSizeImageUrl || ''} alt="" />
                    <Typography variant="subtitle1" className={styles.category__name}>
                      {category.name}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
