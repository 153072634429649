import { getBlogs } from 'services/orchestrator.service';
import { useQuery } from '@tanstack/react-query';
import { getData } from 'utils';

export const useBlogs = () => {
  const { data: blogs } = useQuery({
    queryKey: ['blogs'],
    queryFn: getData(getBlogs),
  });

  return { blogs };
};
