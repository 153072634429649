export const Colors = {
  magentaPink: '#cc3399',
  magentaPinkDark: '#a3287a',
  magentaPinkLight: '#f9eaf4',

  deepYellow: '#ff9933',
  deepYellowDark: '#cc7a28',
  deepYellowLight: '#fff4ea',

  violet: '#6a52aa',
  violetDark: '#544188',
  violetLight: '#f0edf6',

  red: '#ff0000',
  redDark: '#cc0000',
  redLight: '#ffe5e5',

  green: '#3bb87d',
  greenDark: '#2f9364',
  greenLight: '#ebf7f2',

  magentaHover: '#A02878',
  deepYellowHover: '#E28D30',
  darkGray: '#121212',
  midDarkGray: '#575757',
  midLightGray: '#999999',
  gray: '#cccccc',
  lightGray: '#E4E4E4',
  antiFlashWhite: '#F2F3F4',
  secondaryLight: '#F7F8F9',
  background: '#FAFAFA',

  white: '#FFFFFF',
  black: '#000000',
};
