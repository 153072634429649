import { ProductDetailsAttributeModelDto, ProductAttributeValueModelDto } from 'otl-codegen/dist/marketplace-frontend';
import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react';
import { HTMLAttributes, useState } from 'react';
import { Typography } from 'design-system';
import styles from './styles.module.scss';
import { FreeMode } from 'swiper/modules';
import { Box } from '@mui/material';

type Props = {
  attr: ProductDetailsAttributeModelDto;
  onAttributeChange: ({
    attr,
    attrValue,
  }: {
    attr: ProductDetailsAttributeModelDto;
    attrValue: ProductAttributeValueModelDto;
  }) => void;
} & HTMLAttributes<HTMLDivElement>;

export const ColorsBar: React.FC<Props> = ({ attr, onAttributeChange, ...props }) => {
  const [attrValue, setAttrValue] = useState<ProductAttributeValueModelDto | null>();
  const [activeSlide, setActiveSlide] = useState<number | null>();

  const handleSlideClick = (attrValue: ProductAttributeValueModelDto, newValue: number) => {
    setAttrValue(attrValue);
    setActiveSlide(newValue);
    onAttributeChange({ attr, attrValue });
  };

  const colorSwiperParams: SwiperProps = {
    direction: 'horizontal',
    spaceBetween: 8,
    slidesPerView: 7.5,
    modules: [FreeMode],
    breakpoints: { 0: { slideToClickedSlide: true }, 768: { slideToClickedSlide: false } },
  };

  return (
    <div {...props}>
      <Box className="mb-1">
        <Typography variant="subtitle2" className="text-darkGray">
          {attr.name} :{' '}
          <Typography variant="body2" className="text-midDarkGray" component="span">
            {attrValue?.name}
          </Typography>
        </Typography>
      </Box>

      <Swiper {...colorSwiperParams}>
        {attr.values &&
          attr.values.map((attrValue, idx) => (
            <SwiperSlide
              className={`${styles.swiper__slide} ${activeSlide === idx ? styles.active__slide : ''}`}
              onClick={() => handleSlideClick(attrValue, idx)}
            >
              <Box className={styles.outline__box}>
                <Box className={styles.border__box}>
                  <Box
                    className={styles.color__bg}
                    style={{ backgroundColor: attrValue.colorSquaresRgb ? attrValue.colorSquaresRgb : '' }}
                  />
                </Box>
              </Box>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};
