import { Autocomplete as MuiAutocomplete, InputLabelProps, TextFieldProps } from '@mui/material';
import { ErrorFeedback } from '../../../typographies/error-feedback';
import { useCallback, useEffect, useState } from 'react';
import { Controller, Control } from 'react-hook-form';
import { TextField } from '../../core/text-field';
import { Checkbox } from '../../core/checkbox';
import { Close } from '@mui/icons-material';
import { InputLabel } from 'src/index';
import { Chip } from '../../../chip';

export interface MultipleAutocompleteProps {
  control: Control<any>;
  name: string;
  options: any[];
  limit?: number;
  setSelectedValues: (val: any[]) => void;
  defaultValues?: any[];
  inputLabelProps?: InputLabelProps;
  textFieldProps?: TextFieldProps;
}

export const ControlledMultipleAutocomplete = ({
  control,
  name,
  options,
  limit,
  setSelectedValues,
  defaultValues,
  inputLabelProps,
  textFieldProps,
}: MultipleAutocompleteProps) => {
  const [limitReached, setLimitReached] = useState(false);
  const [values, setValues] = useState<any>(defaultValues);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setValues(defaultValues);
    limit && defaultValues && setLimitReached(defaultValues?.length >= limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const onSelect = (newValues: any) => {
    setValues(newValues);
    setSelectedValues(newValues);
    limit && setLimitReached(newValues?.length >= limit);
  };

  const checkDisable = useCallback((option: any) => limitReached && !values.includes(option), [limitReached, values]);

  const handleOnOpen = (e: any) => {
    if (e.type !== 'change') setOpen(true);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { onChange, ref, value } = field;
        const selectedValues = value || [];

        return (
          <>
            {inputLabelProps?.children && <InputLabel {...inputLabelProps} />}

            <MuiAutocomplete
              multiple
              open={open}
              options={options}
              value={selectedValues}
              getOptionLabel={option => option}
              renderInput={fieldParams => (
                <TextField
                  {...fieldParams}
                  {...textFieldProps}
                  name={`${name}-${Math.random()}`}
                  inputRef={ref}
                  error={!!fieldState.error}
                  InputProps={{ ...fieldParams.InputProps }}
                />
              )}
              onChange={(event: any, newValue: any) => {
                onChange(newValue ? newValue : null);
                onSelect(newValue.length > 0 ? newValue : null);
              }}
              onOpen={handleOnOpen}
              onClose={() => setOpen(false)}
              getOptionDisabled={checkDisable}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {option}
                </li>
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    label={option}
                    variant="outlined"
                    className="m-1 z-[999]"
                    deleteIcon={<Close className="fill-midLightGray text-base border-1 border-midLightGray" />}
                    key={option}
                  />
                ))
              }
            />

            {fieldState.error && <ErrorFeedback message={fieldState.error.message} />}
          </>
        );
      }}
    />
  );
};
