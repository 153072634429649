import {
  ProductAttributeValueModelDto,
  ProductDetailsAttributeChangeResponse,
  ProductDetailsAttributeModelDto,
  ProductDetailsModelDto,
} from 'otl-codegen/dist/marketplace-frontend';
import { updateProductDetailsAttribute } from 'services/orchestrator.service';
import { ProductAttributeEnum } from 'services/types/generic/enums';
import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { findProductAttributeById } from 'utils';
import { useNavigate } from 'react-router-dom';
import { getUnitShort } from 'design-system';

export const useMainDetails = ({
  productDetails,
  onProductAttributeChange,
}: {
  productDetails: ProductDetailsModelDto;
  onProductAttributeChange: Function;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>();
  const [productAttributeChange, setProductAttributeChange] = useState<ProductDetailsAttributeChangeResponse>();
  const [productId, setProductId] = useState<number>();
  const [attributeChangeBody, setAttributeChangeBody] = useState<Record<string, number>>({});
  const [copied, setCopied] = useState<Boolean>(false);
  const [contentScrolled, setContentScrolled] = useState<Boolean>(false);

  const contentRef = useRef<HTMLDivElement>(null);

  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const url = window.location.href;
  const { productAttributes } = productDetails;

  const colorsAttribute = productAttributes && findProductAttributeById(productAttributes, ProductAttributeEnum.Colors);
  const sizeAttribute = productAttributes && findProductAttributeById(productAttributes, ProductAttributeEnum.Size);
  const thicknessAttribute =
    productAttributes && findProductAttributeById(productAttributes, ProductAttributeEnum.Thickness);
  const finishAttribute = productAttributes && findProductAttributeById(productAttributes, ProductAttributeEnum.Finish);

  const handleShareClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

    if (isDownMd && navigator.share) {
      navigator.share({
        title: `${productDetails.seName}`,
        text: `Check out ${productDetails.seName} on `,
        url: document.location.href,
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);

    setTimeout(() => {
      setCopied(false);
    }, 300);
  };

  const callAttributeChange = ({
    attr,
    attrValue,
  }: {
    attr: ProductDetailsAttributeModelDto;
    attrValue: ProductAttributeValueModelDto;
  }) => {
    setAttributeChangeBody({
      ...attributeChangeBody,
      [`product_attribute_${attr.id}`]: attrValue.id,
    });
    setProductId(attr.productId);
  };

  const updateProductAttribute = async (productId: number) => {
    const [{ data: productAttributeChangeResponse }] = await Promise.all([
      updateProductDetailsAttribute({
        productId,
        body: attributeChangeBody,
      }),
    ]);
    setProductAttributeChange(productAttributeChangeResponse);
    onProductAttributeChange(productAttributeChangeResponse);
  };

  const getSku = () => {
    if (productAttributeChange?.sku) return productAttributeChange?.sku;
    else return productDetails.sku;
  };

  const getUnit = () => {
    let unit: string = '';
    if (productDetails.unit) {
      unit = `/ ${productDetails.unit}`;
      if (getUnitShort(productDetails.unit)) {
        unit = unit + ` (${getUnitShort(productDetails.unit)})`;
      }
    }
    return unit;
  };

  const getPrice = () => {
    if (productAttributeChange?.price) return `${productAttributeChange?.price}`;
    else return `${productDetails?.productPrice.price}`;
  };

  useEffect(() => {
    if (productId) updateProductAttribute(productId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeChangeBody, productId]);

  useEffect(() => {
    const container = contentRef.current;

    const handleScroll = () => {
      const scrollTop = container?.scrollTop || 0;
      setContentScrolled(scrollTop > 0);
    };

    container?.addEventListener('scroll', handleScroll);
    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return {
    anchorEl,
    open,
    handleShareClick,
    handleClose,
    id,
    url,
    navigate,
    colorsAttribute,
    sizeAttribute,
    thicknessAttribute,
    finishAttribute,
    contentRef,
    callAttributeChange,
    getSku,
    getPrice,
    getUnit,
    copied,
    setCopied,
    contentScrolled,
  };
};
