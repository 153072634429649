import { useFeatureFlagEnabled } from 'posthog-js/react';
import { OldAboutOtrovato } from './old-about-otrovato';
import { NewAboutOtrovato } from './new-about-otrovato';
import { Loader } from 'design-system';

export const AboutOtrovato = () => {
  const flagEnabled = useFeatureFlagEnabled('about-us');

  if (flagEnabled === undefined) {
    return <Loader />;
  }

  return <>{flagEnabled ? <NewAboutOtrovato /> : <OldAboutOtrovato />}</>;
};
