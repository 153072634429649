import {
  getFaqs,
  getHomePageCategories,
  getHomePageProducts,
  getPromotions,
  getTopVendors,
} from 'services/orchestrator.service';
import { useLoading } from 'contexts/loadingContext';
import { useQueries } from '@tanstack/react-query';
import { useEffect } from 'react';
import { getData } from 'utils';

export const useHomePage = () => {
  const { startLoading, stopLoading } = useLoading();

  const [
    { data: promotions, isLoading: isPromotionsLoading },
    { data: bestSellingProducts, isLoading: isProductsLoading },
    { data: homePageCategories, isLoading: isCategoriesLoading },
    { data: topVendors, isLoading: isVendorsLoading },
    { data: faqs, isLoading: isFaqsLoading },
  ] = useQueries({
    queries: [
      { queryKey: ['promotions'], queryFn: getData(getPromotions) },
      { queryKey: ['bestSellingProducts'], queryFn: getData(getHomePageProducts) },
      { queryKey: ['homePageCategories'], queryFn: getData(getHomePageCategories) },
      { queryKey: ['topVendors'], queryFn: getData(getTopVendors) },
      { queryKey: ['faqs'], queryFn: getData(getFaqs) },
    ],
  });

  const isLoading =
    isPromotionsLoading || isProductsLoading || isCategoriesLoading || isVendorsLoading || isFaqsLoading;

  useEffect(() => {
    if (isLoading) startLoading();
    else stopLoading();
  }, [isLoading, startLoading, stopLoading]);

  return {
    bestSellingProducts,
    homePageCategories,
    promotions,
    topVendors,
    faqs,
    isLoading,
  };
};
