import { getRfqSignedUrl, updateSecondStepRfq } from 'services/orchestrator.service';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useRfqByToken } from 'hooks';
import * as Yup from 'yup';
import axios from 'axios';

export const useSubmitRfqSecondStep = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const token = pathname.split('/').pop();
  const { updateBreadcrumbs } = useBreadcrumbs();
  const [files, setFiles] = useState<File[]>([]);
  const [pageLoading, setPageLoading] = useState<boolean>(true);

  useRfqByToken({ token, setPageLoading });

  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  document.querySelector('#hubspot-messages-iframe-container')?.classList.add('translate-x-full');

  const formSchema = Yup.object().shape({
    description: Yup.string().optional(),
  });
  const { getValues, control } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(formSchema),
  });

  const acceptedFileTypes = {
    'image/png': ['.png'],
    'image/jpg': ['.jpg'],
    'image/jpeg': ['.jpeg'],
    'text/plain': ['.txt'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc'],
    'text/csv': ['.csv'],
  };

  // Mutation for uploading a single file
  const { mutateAsync: uploadFileMutation, isPending: isPendingUploadFile } = useMutation({
    mutationKey: ['uploadFile'],
    mutationFn: async ({ file, token }: { file: File; token: string }) => {
      const { data: url } = await getRfqSignedUrl({ token, fileName: file.name });
      await axios.put(url, file, { headers: { 'Content-Type': 'application/octet-stream' } });
    },
    onError: error => {
      toast.error(error.message);
    },
  });

  // Mutation for updating the RFQ
  const { mutateAsync: updateRfqSecondStepMutation, isPending: isPendingRfqUpdate } = useMutation({
    mutationKey: ['updateRfqSecondStep', token],
    mutationFn: async ({ description, token }: { description: string; token: string }) => {
      await updateSecondStepRfq({ body: { description }, token });
    },
    onSuccess: () => {
      navigate(`/rfq/submit/third-step/${token}`, { replace: true });
    },
    onError: error => {
      toast.error(error.message);
    },
  });

  const onSubmit = async () => {
    const values = getValues();

    for (const file of files) {
      if (token) {
        await uploadFileMutation({ file, token });
      }
    }

    if (token) await updateRfqSecondStepMutation({ description: values.description || '', token });
  };

  useEffect(() => {
    updateBreadcrumbs([{ label: 'RFQ Submission', link: '/rfq/submit/second-step' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const elements = document.getElementsByTagName('input');
    for (const element of elements) {
      element.autocomplete = 'new-password';
    }
  }, []);

  return {
    setFiles,
    onSubmit,
    control,
    acceptedFileTypes,
    isDownMd,
    pageLoading,
    isPendingUploadFile,
    isPendingRfqUpdate,
  };
};
