import { RfqStatusEnum } from 'services/types/generic/enums';
import { RFQ } from 'services/types/generic/rfq-entity';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

export const useCheckRfq = () => {
  const navigate = useNavigate();

  const getRfqNavigationPath = useCallback((rfq: RFQ | undefined, token: string): string | null => {
    if (token && rfq) {
      if (rfq?.status !== (RfqStatusEnum.Draft || RfqStatusEnum.Incomplete)) {
        return `/rfq/details/${rfq.id}`;
      } else if (rfq.description !== null) {
        return `/rfq/submit/third-step/${token}`;
      }
    }
    return null;
  }, []);

  const checkRfq = useCallback(
    (rfq: RFQ | undefined, token: string, setPageLoading: any) => {
      const path = getRfqNavigationPath(rfq, token);
      if (path) navigate(path, { replace: true });
      setPageLoading(false);
    },
    [getRfqNavigationPath, navigate],
  );

  return { checkRfq };
};
