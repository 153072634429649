import { ProductDetailsModelDto, ProductSpecificationAttributeModelDto } from 'otl-codegen/dist/marketplace-frontend';
import { useEffect, useState } from 'react';

type Props = {
  productDetails?: ProductDetailsModelDto;
};

export const useProductDetails = ({ productDetails }: Props) => {
  const [essentialDetailsAttributes, setEssentialDetailsAttributes] = useState<{ key: string; value: string }[]>();
  const [hasEssentialDetails, setHasEssentialDetails] = useState<boolean>();
  const [supplyAbilityAttribute, setSupplyAbilityAttribute] = useState<string | null>();
  const [hasSupplyAbility, setHasSupplyAbility] = useState<boolean>();
  const [packagingDeliveryAttribute, setPackagingDeliveryAttribute] = useState<string | null>();
  const [hasPackagingDelivery, setHasPackagingDelivery] = useState<boolean>();
  const [leadTimeAttribute, setLeadTimeAttribute] = useState<string | null>();
  const [dimensionsAttributes, setDimensionsAttributes] = useState<{ key: string; value: string }[]>();
  const [hasDimensions, setHasDimensions] = useState<boolean>();
  const [hasLeadTime, setHasLeadTime] = useState<boolean>();

  useEffect(() => {
    if (productDetails?.productSpecificationModel?.groups) {
      const attributes = productDetails.productSpecificationModel.groups[0].attributes;
      setEssentialDetailsAttributes(mapEssentialDetailsAttributesToKeyValuePair(attributes));
      setHasEssentialDetails(!!(attributes && attributes?.length > 0));
    }
    if (productDetails) {
      setSupplyAbilityAttribute(productDetails.supplyAbility || null);
      setHasSupplyAbility(!!productDetails.supplyAbility);
      setDimensionsAttributes(mapDimensionsToKeyValuePair(productDetails));
      setHasDimensions(checkHasDimensions(productDetails));
      setPackagingDeliveryAttribute(productDetails.packagingAndDelivery || null);
      setHasPackagingDelivery(!!productDetails.packagingAndDelivery);
      setLeadTimeAttribute(productDetails.estimatedLeadTime || null);
      setHasLeadTime(!!productDetails.estimatedLeadTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetails]);

  const checkHasDimensions = (details: ProductDetailsModelDto): boolean => {
    return !!(
      details?.weight ||
      details?.productLength ||
      details?.productWidth ||
      details?.productHeight ||
      details?.depth ||
      details?.diameter ||
      details?.thickness
    );
  };

  const mapEssentialDetailsAttributesToKeyValuePair = (attributes: ProductSpecificationAttributeModelDto[] | null) => {
    const result: { key: string; value: string }[] = [];
    attributes &&
      attributes.forEach(attribute => {
        attribute.name &&
          attribute.values &&
          result.push({ key: attribute.name, value: attribute.values[0].valueRaw! });
      });
    return result;
  };

  const mapDimensionsToKeyValuePair = (productDetails: ProductDetailsModelDto) => {
    const keyMappings = {
      productLength: 'Length',
      productWidth: 'Width',
      productHeight: 'Height',
      weight: 'Weight',
      depth: 'Depth',
      diameter: 'Diameter',
      thickness: 'Thickness',
    };
    const reducedArray: { key: string; value: string }[] = [];

    for (const key in keyMappings) {
      if (productDetails.hasOwnProperty(key) && productDetails[key] !== null && productDetails[key] !== '') {
        reducedArray.push({ key: keyMappings[key], value: productDetails[key] });
      }
    }

    return reducedArray;
  };

  return {
    essentialDetailsAttributes,
    hasEssentialDetails,
    supplyAbilityAttribute,
    hasSupplyAbility,
    dimensionsAttributes,
    hasDimensions,
    packagingDeliveryAttribute,
    hasPackagingDelivery,
    leadTimeAttribute,
    hasLeadTime,
  };
};
