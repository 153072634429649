import {
  ControlledAutocompleteSelect,
  ControlledMultipleAutocomplete,
  ControlledPhoneInput,
  BusinessTypesOptions,
  Button,
  CheckedSuccessfully,
  Modal,
  ControlledTextField,
  Typography,
  LoadingButton,
} from 'design-system';
import { Box, Grid } from '@mui/material';
import { useProfile } from './useProfile';
import { userHasMetadata } from 'utils';
import { Helmet } from 'react-helmet';

export const Profile = () => {
  const {
    control,
    onSubmit,
    getValues,
    countries,
    handleSubmit,
    loading,
    handleCountryChange,
    handleBusinessTypeChange,
    selectedCountry,
    setUserData,
    checkIsFormDirty,
    successfulOpen,
    myUser,
    handleCloseDialog,
  } = useProfile();

  return (
    <>
      <Helmet>
        <title>Manage Your Account</title>
        <meta name="description" content="Update your personal information, and check submitted quotations" />
        <meta
          name="keywords"
          content="Buy from Africa, sale on construction material, best B2B platform to import from Africa, African B2B Marketplace trading, furniture b2b marketplace, Buy construction material,  Buy food and beverage, Buy furniture, Supply new products in my country, Start Trading in Africa"
        />
      </Helmet>
      <Modal
        open={successfulOpen}
        image={
          <Box className="w-16 h-16">
            <CheckedSuccessfully />
          </Box>
        }
        header={
          <Typography variant="h6" component="h6" className="text-center text-darkGray">
            Updated Successfully
          </Typography>
        }
        content={
          <Typography variant="body2" className="text-center text-midDarkGray">
            Your profile was updated successfully
          </Typography>
        }
        secondaryButtonText="Ok"
        handleClose={() => handleCloseDialog()}
      />

      <Box className="bg-white p-12 max-w-[1120px] m-auto max-sm:bg-transparent max-sm:px-0 max-sm:pt-0">
        <Typography component="h3" variant="h3" className="text-darkGray">
          Your Profile
        </Typography>

        <Grid container spacing={3} className="mt-6 max-sm:mt-0">
          <Grid item xs={12} md={6}>
            <ControlledTextField
              name="name"
              control={control}
              inputLabelProps={{ required: true, children: 'Full Name' }}
              textFieldProps={{
                placeholder: 'Enter your name',
                InputProps: { inputProps: { maxLength: 125 } },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ControlledTextField
              name="email"
              control={control}
              inputLabelProps={{ required: true, children: 'Email', disabled: true }}
              textFieldProps={{
                placeholder: 'Enter your Email',
                disabled: true,
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ControlledAutocompleteSelect
              name="country"
              control={control}
              options={countries}
              placeholder="Choose your country"
              inputLabelProps={{ required: true, children: 'Country' }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ControlledPhoneInput
              name="phone"
              control={control}
              inputLabelProps={{ required: true, children: 'Phone Number' }}
              handleCountryChange={handleCountryChange}
              selectedCountry={selectedCountry}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ControlledTextField
              name="companyName"
              control={control}
              inputLabelProps={{ required: true, children: 'Company Name' }}
              textFieldProps={{
                placeholder: 'Enter your Company name',
                InputProps: { inputProps: { maxLength: 125 } },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ControlledMultipleAutocomplete
              name="businessType"
              control={control}
              options={BusinessTypesOptions}
              setSelectedValues={handleBusinessTypeChange}
              limit={3}
              defaultValues={getValues('businessType')}
              inputLabelProps={{ required: true, children: 'Business Type' }}
              textFieldProps={{
                placeholder: 'Choose your business type',
                InputProps: { inputProps: { maxLength: 125 } },
              }}
            />
          </Grid>
        </Grid>

        <Box className="flex justify-end items-center mt-10">
          {userHasMetadata(myUser) && (
            <Button
              size="large"
              variant="outlined"
              color="inherit"
              className="me-3 w-40 text-midLightGray"
              onClick={() => setUserData()}
              disabled={!checkIsFormDirty()}
              label="Cancel"
            />
          )}
          <LoadingButton
            size="large"
            variant="contained"
            color="magentaPink"
            className="w-40 max-sm:w-full"
            loading={loading}
            onClick={handleSubmit(onSubmit)}
            disabled={!checkIsFormDirty() && userHasMetadata(myUser)}
            label="Save"
          />
        </Box>
      </Box>
    </>
  );
};
