import { ReactComponent as SellerIcon } from 'design-system/public/icons/become-a-seller-menu.svg';
import { ReactComponent as SearchIcon } from 'design-system/public/icons/search-about.svg';
import { ReactComponent as NegotiateIcon } from 'design-system/public/icons/negotiate.svg';
import { ReactComponent as Binoculars } from 'design-system/public/icons/binoculars.svg';
import { ReactComponent as Africa } from 'design-system/public/icons/africa.svg';
import { ReactComponent as Rocket } from 'design-system/public/icons/rocket.svg';
import { ReactComponent as CartIcon } from 'design-system/public/icons/cart.svg';
import { ReactComponent as Kenya } from 'design-system/public/icons/kenya.svg';
import { ReactComponent as Egypt } from 'design-system/public/icons/egypt.svg';
import { AppContainer, Button, Typography } from 'design-system';
import { FindUsLocation } from './components/findUsLocation';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { CustomAccordion } from './components/accordion';
import { useMediaQuery, useTheme } from '@mui/material';
import { SignupCard } from './components/signupCard';
import { useNavigate } from 'react-router-dom';
import { aboutOtrovatoData } from './data';
import styles from './styles.module.scss';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';
import { useEffect } from 'react';

export const NewAboutOtrovato = () => {
  const { updateBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();
  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));
  const iframeLink = 'https://www.youtube.com/embed/BjVMmagqkhk';

  useEffect(() => {
    updateBreadcrumbs([{ label: 'About otrovato', link: '/about-otrovato' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Who we are</title>
        <meta name="description" content="Know about our company, who we are and who we operate!" />
        <meta
          name="keywords"
          content="best african products, easiest way to buy from africa, Trade Solutions, Online Trading Marketplace, Import construction material, Import food and beverage,  African  Sellers, Trusted African Suppliers Network, Easiest way to import from Africa, Importing steps, expand my business, ways to expand my company, African B2B Marketplace Registration"
        />
      </Helmet>

      <Box className="bg-offWhiteBg pt-20 mt-[-50px] max-md:pt-14 max-md:mt-[-40px]">
        <AppContainer>
          <section className={styles.about__img}>
            <div className={styles.about__overlay} />
            <div className={styles.about__img__header}>
              <Typography variant="h2" component="h2">
                otrovato is your ideal <span className="text-deepYellow">B2B</span> marketplace
              </Typography>
              <Typography variant={isDownMd ? 'caption' : 'body1'} className="pt-3 max-md:pt-2">
                where trading is brought at your fingertips to unlock business opportunities.
              </Typography>
            </div>
          </section>

          <Typography variant="h3" component="h3" className="text-violet text-center pt-12 max-md:pt-10">
            Who we are ?
          </Typography>
          <Typography variant="body1" className="text-midDarkGray pt-3 text-center">
            O Trade & Logistics (OTL) has officially launched its new project “otrovato” under the umbrella of Orascom
            Investment Holding (OIH) as a welcoming initiative for business owners all over Africa to start a journey of
            growing businesses regionally and unlocking limitless trading opportunities.
          </Typography>
          <Typography variant="body1" className="text-midDarkGray pt-3 text-center">
            otrovato is a B2B marketplace designed to help buyers and sellers connect while simplifying the process of
            trading to make it accessible, efficient, and secure.
          </Typography>
          <Typography variant="h3" component="h3" className="text-violet text-center pt-12 max-md:pt-10">
            Who is it designed for ?
          </Typography>
          <Typography variant="body1" className="text-midDarkGray pt-3 text-center">
            Whether you're a buyer who's looking for products at the best attainable prices or a seller who seeks
            unlimited business opportunities, otrovato will facilitate your trading needs with an end-to-end business
            cycle from browsing to shipping all over Africa.
          </Typography>

          <Box className="flex gap-6 pt-3 max-md:flex-wrap ">
            <Box className="basis-1/2 max-md:basis-full">
              <SignupCard
                icon={<CartIcon className={styles.card__icon} />}
                title="Buyer"
                subtitle="As a buyer, otrovato brings together all the steps of a sourcing process under one single platform. Save your time and money, find competitive prices, negotiate with suppliers, and ask for shipping recommendations, all are accessible to you on our online B2B marketplace, without having to go too far."
                buttonTitle="Sign up as a buyer"
                type="buyer"
              />
            </Box>
            <Box className="basis-1/2 max-md:basis-full ">
              <SignupCard
                icon={<SellerIcon className={styles.card__icon} />}
                title="Seller"
                subtitle="As a seller, using otrovato would not be limited to showcasing your products and facilitating your business deals. This is additionally an opportunity to expand your network, as well as connect with other suppliers and buyers regionally. You’ll also be able to access different markets outside your local market, each with their different trading advantages. "
                buttonTitle="Sign up as a seller"
                type="seller"
              />
            </Box>
          </Box>

          <Box className="flex justify-center py-12 max-md:pt-10">
            <iframe
              width={isDownMd ? '100%' : '72%'}
              height={isDownMd ? '200' : '500'}
              className="rounded-xl"
              src={iframeLink}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>

          <Box className={`${isDownMd ? 'flex flex-col gap-5' : 'flex'} pb-12`}>
            <Box className="basis-2/5">
              <Typography variant="h3" component="h3" className="text-violet ">
                Why choose otrovato ?
              </Typography>
              <Typography variant="body1" className="text-midDarkGray pt-3 max-w-[420px]">
                otrovato helps buyers and sellers access new markets via our trusted marketplace, by facilitating
                trading in various industries. otrovato safeguards business quality through comprehensive inspections
                and seamless end-to-end shipping.
              </Typography>
            </Box>
            <Box className="basis-3/5">
              <CustomAccordion data={aboutOtrovatoData} />
            </Box>
          </Box>
        </AppContainer>
      </Box>

      <Box className="flex items-center gap-12 flex-col py-[120px] max-md:py-[60px] bg-violetLight">
        <Typography variant="h3" component="h3" className="text-violet ">
          How otrovato can help you ?
        </Typography>
        <Box className="flex w-full max-md:flex-col max-md:gap-10">
          <Box className=" flex-1 flex items-center flex-col ">
            <SearchIcon fill="var(--violet)" width={isDownMd ? 32 : 40} height={isDownMd ? 32 : 40} />
            <Typography variant="h5" component="h5" className="text-violet ">
              Browse
            </Typography>
            <Box>
              <Typography variant="body1" className="text-violet ">
                Explore diverse products in one place
              </Typography>
            </Box>
          </Box>
          <Box className="flex flex-1 items-center flex-col ">
            <NegotiateIcon stroke="var(--violet)" width={isDownMd ? 32 : 40} height={isDownMd ? 32 : 40} />
            <Typography variant="h5" component="h5" className="text-violet ">
              Negotiate
            </Typography>
            <Box>
              <Typography variant="body1" className="text-violet">
                Connect directly with sellers to negotiate terms
              </Typography>
            </Box>
          </Box>
          <Box className="flex flex-1  items-center flex-col ">
            <CartIcon stroke="var(--violet)" width={isDownMd ? 32 : 40} height={isDownMd ? 32 : 40} />
            <Typography variant="h5" component="h5" className="text-violet">
              Order
            </Typography>
            <Box>
              <Typography variant="body1" className="text-violet max-w-[324px] text-center">
                Order confidently with quality assurance and shipping support
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="bg-offWhiteBg">
        <AppContainer>
          <Box className="pt-20  flex flex-col">
            <Box className="flex  gap-20  max-md:gap-8  max-md:items-start items-center">
              <Box className="flex  gap-8 max-md:gap-1 items-center max-md:items-start max-md:flex-col">
                <Typography variant="h4" component="h4" className="text-darkGra">
                  Our Vision
                </Typography>
                <Typography variant="body1" className="text-midDarkGray">
                  Make commerce (easy) for everyone (B2B)
                </Typography>
              </Box>
              <Binoculars className="max-md:w-10 max-md:h-10" />
            </Box>
            <Box className="flex gap-20 max-md:gap-8 items-center max-md:items-start self-end mt-20">
              <Rocket className="max-md:w-10 max-md:h-10 shrink-0 grow-1" />
              <Box className="flex  gap-8 max-md:gap-1 items-center  max-md:items-start  max-md:flex-col ">
                <Typography variant="h4" component="h4" className="text-darkGray">
                  Our Mission
                </Typography>
                <Typography variant="body1" className="text-midDarkGray max-w-[700px]">
                  otrovato’s mission is to connect buyers and sellers at one place, make the trading process easy,
                  smooth, and ensure hassle-free transactions with lower risk.
                </Typography>
              </Box>
            </Box>

            <Box className={styles.africa__container}>
              <Africa className={styles.africa__image} />
              <Box className={styles.africa__info}>
                <Typography variant="h2" component="h2" className="text-magentaPink ">
                  otrovato is your partner
                </Typography>
                <Typography variant="body1" className="text-midDarkGray ">
                  in exploring business growth across Africa.
                </Typography>
              </Box>
            </Box>

            <Typography
              variant="h3"
              component="h3"
              className="text-midDarkGray pt-20 text-center self-center max-w-[820px] max-md:pt-5"
            >
              Explore the diversity of products offered across various industries that suit your business needs
            </Typography>
            <Button
              size="large"
              className="self-center mt-6"
              onClick={() => navigate('/all-categories')}
              label="Browse all categories"
            />
          </Box>

          <Typography variant="h3" component="h3" className="text-violet pt-16">
            Find us
          </Typography>

          <FindUsLocation
            flag={<Kenya className="shrink-0  grow-1  max-md:mt-1" />}
            disc="Showroom address: 9 West office suites Ring Road West lands-Ground Floor, Nairobi, Kenya."
          />

          <Box className="pb-16">
            <FindUsLocation
              flag={<Egypt className="shrink-0  grow-1 max-md:mt-1" />}
              disc="Headquarter: 2005 Corniche el Nile, Ramlet Boulak, Bulaq, Cairo Governorate, Egypt"
            />
          </Box>
        </AppContainer>
      </Box>
    </>
  );
};
