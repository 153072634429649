// Import the functions you need from the SDKs you need
import { getMessaging, getToken } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyA546bxazxZSHBCKIsn9Cx4e6j0GarBaAU',
  authDomain: 'my-awesome-project-otrovato.firebaseapp.com',
  projectId: 'my-awesome-project-otrovato',
  storageBucket: 'my-awesome-project-otrovato.appspot.com',
  messagingSenderId: '559614852715',
  appId: '1:559614852715:web:70af300400e88152f5fb2e',
  measurementId: 'G-0KYH1HD701',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  if (permission === 'granted') {
    const token = await getToken(messaging, {
      vapidKey: 'BKQ8oxoINiGORVe1cLhTAIfzwV6gr6d4k6Zgzjeyf9LP6s0zCGxn444gKQE7TMa6DuMkOS8u8SbMOmSpKMbtv6s',
    });
    console.log(token);
  }
};
