// import { ReactComponent as ChatPopupIcon } from 'design-system/public/icons/message-center-popup-icon.svg';
import { ReactComponent as MessengerIcon } from 'design-system/public/icons/filled-messenger-icon-orange.svg';
import { useMessageCenterContext } from 'contexts/message-center-context';
import { ChannelListMobile } from './components/channel-list-mobile';
import { EmptyChannels, Loader, Typography } from 'design-system';
import { SendBirdProvider } from '@sendbird/uikit-react';
import { BottomNavigation } from 'components';
import styles from './styles.module.scss';
import { useCustomAuth } from 'hooks';
import { Box } from '@mui/material';

export const MessageCenter = () => {
  const { APP_ID, sessionToken, hasChannels, showMessageCenter, loader } = useMessageCenterContext();
  const { user } = useCustomAuth();

  if (loader) return <Loader />;

  return (
    <>
      {showMessageCenter && (
        <SendBirdProvider appId={APP_ID} userId={user?.email!} accessToken={sessionToken} isVoiceMessageEnabled>
          <Box className={styles.message__center__container}>
            <Box className={styles.message__center__header}>
              <Box className="flex items-center">
                {/* <ChatPopupIcon className="me-3" /> */}
                <MessengerIcon className="me-3 h-[18px] w-[18px]" />

                <Typography variant="subtitle1" className="text-deepYellow">
                  Message Center
                </Typography>
              </Box>
            </Box>

            {hasChannels && (
              <Box className={styles.channel__list}>
                <ChannelListMobile />
              </Box>
            )}

            {!hasChannels && (
              <Box className={styles.checked__container}>
                <Box className={styles.lottie__container}>
                  <EmptyChannels />
                </Box>
                <Typography variant="subtitle1" className={styles.info}>
                  No available channels. Explore products to contact with sellers.
                </Typography>
              </Box>
            )}

            <BottomNavigation />
          </Box>
        </SendBirdProvider>
      )}
    </>
  );
};
