import { ErrorFeedback } from '../../../typographies/error-feedback';
import { TextFieldProps, InputLabelProps } from '@mui/material';
import { InputLabel } from '../../../typographies/input-label';
import { Controller, Control } from 'react-hook-form';
import { TextField } from '../../core/text-field';

export interface ControlledTextFieldProps {
  name: string;
  control: Control<any>;
  inputLabelProps?: InputLabelProps;
  textFieldProps?: TextFieldProps;
}

export const ControlledTextField = ({ name, inputLabelProps, textFieldProps, control }: ControlledTextFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <>
          {inputLabelProps?.children && <InputLabel {...inputLabelProps} />}

          <TextField {...textFieldProps} {...field} name={`${name}-${Math.random()}`} error={!!fieldState.error} />

          {fieldState.error && <ErrorFeedback message={fieldState.error.message} />}
        </>
      )}
    />
  );
};
