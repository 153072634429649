import { usePagination } from 'react-instantsearch';
import { Pagination } from 'design-system';

export const AlgoliaPagination = () => {
  const { nbPages, currentRefinement, refine, nbHits } = usePagination();

  const onChangePage = (value: number) => {
    refine(value - 1);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // You can change this to 'auto' if you want instant scrolling
      });
    }, 150);
  };

  return (
    <>
      {nbHits > 0 && <Pagination count={nbPages} page={currentRefinement + 1 || 1} handleChangePage={onChangePage} />}
    </>
  );
};
